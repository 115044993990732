export default function Organization(id,
                                     name) {
    this.id = id;
    this.name = name;

    this.toJson = function () {
        return {
            id: this.id,
            name: this.name,
        };
    }
}

Organization.fromJson = function (json) {
    return new Organization(
        json.id,
        json.name);
};