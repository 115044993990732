import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import Title from "./Title";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 24,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
}));

export default function Form({title, onSubmit, submitButtonLabel, children}) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Title>{title}</Title>
            <form onSubmit={onSubmit}>
                {children}
                <Grid container justify="flex-end" className={classes.container}>
                    <Button color="primary" type="submit">{submitButtonLabel}</Button>
                </Grid>
            </form>
        </Paper>
    );
}