export default function Group(id,
                               name) {
    this.id = id;
    this.name = name;

    this.toJson = function() {
        return {
            id: this.id,
            name: this.name,
        };
    }
}

Group.fromJson = function(json) {
    return new Group(
        json.id,
        json.name);
};