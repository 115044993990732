import GwInfo from "./GwInfo";

export default function RxMetadata(
    time,
    technology,
    band,
    channel,
    dataRate,
    gwInfo) {
    this.time = time;
    this.technology = technology;
    this.band = band;
    this.channel = channel;
    this.dataRate = dataRate;
    this.gwInfo = gwInfo;
}

RxMetadata.fromJson = function (json) {
    return new RxMetadata(
        new Date(Date.parse(json.time)),
        json.technology,
        json.band,
        json.channel,
        json.dataRate,
        json.gwInfo.map(gwInfo => GwInfo.fromJson(gwInfo)));
};