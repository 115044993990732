import React from 'react';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import Title from './Title';
import moment from "moment";

function createData(time, value) {
    return { time, value };
}

export default function TimedValueBarChart({ title, label, color, format, entries }) {
    var tmp = entries.map(entry => createData(moment(entry.time).format(format), entry.value));
    return (
        <React.Fragment>
            <Title>{title}</Title>
            <ResponsiveContainer>
                <BarChart
                    data={tmp.slice().reverse()}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis dataKey="time" />
                    <YAxis>
                        <Label angle={270} position="left" style={{ textAnchor: 'middle' }}>
                            {label}
                        </Label>
                    </YAxis>
                    <Bar dataKey="value" stroke={color} fill={color} />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Tooltip separator=": " formatter={(a, b, c) => {
                        return [a.toString(), label]
                    }} />
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
