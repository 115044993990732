import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import GroupForm from "./GroupForm";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}));

export default function GroupSettings({group, onUpdate}) {
    const classes = useStyles();

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    function onSubmit(group) {
        onUpdate(group, () => setSnackbarOpen(true));
    }

    return (
        <React.Fragment>
            <Container maxWidth={false} className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        {group && <GroupForm edit initialGroup={group} onSubmit={onSubmit}/>}
                    </Grid>
                </Grid>
            </Container>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                onClose={event => setSnackbarOpen(false)}
                autoHideDuration={2000}
                message="Zapisano"
            />
        </React.Fragment>
    );
}