import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { saveAs } from "file-saver"
import { convertArrayToCSV } from "convert-array-to-csv";
import XLSX from 'xlsx';
import { calculateState, calculateUsage, chooseLastAlarm, loraDataRateToValue } from "./common";
import moment from "moment";
import { Link } from "react-router-dom";
import RichTable from "./RichTable";
import iconv from "iconv-lite";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    panel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
    },
    richTable: {
        whiteSpace: 'nowrap',
        height: 987
    }
}));

export default function GroupDevices({
    organizationId,
    groupId,
    since,
    until,
    devices,
    deviceStatuses,
    onDelete,
    admin
}) {
    const classes = useStyles();

    function deviceHasAlarms(deviceStatus) {
        if (deviceStatus) {
            return ((deviceStatus.meter1Connected ? (deviceStatus.meter1Connected.value === true ? deviceStatus.meter1Connected.value : false) : false)
                || (deviceStatus.meter2Connected ? (deviceStatus.meter2Connected.value === true ? deviceStatus.meter2Connected.value : false) : false)
                || (deviceStatus.meter1Disconnected ? (deviceStatus.meter1Disconnected.value === true ? deviceStatus.meter1Disconnected.value : false) : false)
                || (deviceStatus.meter2Disconnected ? (deviceStatus.meter2Disconnected.value === true ? deviceStatus.meter2Disconnected.value : false) : false)
                || (deviceStatus.meter1DoseExceeded ? (deviceStatus.meter1DoseExceeded.value === true ? deviceStatus.meter1DoseExceeded.value : false) : false)
                || (deviceStatus.meter2DoseExceeded ? (deviceStatus.meter2DoseExceeded.value === true ? deviceStatus.meter2DoseExceeded.value : false) : false)
                || (deviceStatus.meter1HoseCracked ? (deviceStatus.meter1HoseCracked.value === true ? deviceStatus.meter1HoseCracked.value : false) : false)
                || (deviceStatus.meter2HoseCracked ? (deviceStatus.meter2HoseCracked.value === true ? deviceStatus.meter2HoseCracked.value : false) : false)
                || (deviceStatus.meter1Leak ? (deviceStatus.meter1Leak.value === true ? deviceStatus.meter1Leak.value : false) : false)
                || (deviceStatus.meter2Leak ? (deviceStatus.meter2Leak.value === true ? deviceStatus.meter2Leak.value : false) : false)
                || (deviceStatus.meter1MinUsageNotReached ? (deviceStatus.meter1MinUsageNotReached.value === true ? deviceStatus.meter1MinUsageNotReached.value : false) : false)
                || (deviceStatus.meter2MinUsageNotReached ? (deviceStatus.meter2MinUsageNotReached.value === true ? deviceStatus.meter2MinUsageNotReached.value : false) : false)
                || (deviceStatus.valve1Leak ? (deviceStatus.valve1Leak.value === true ? deviceStatus.valve1Leak.value : false) : false)
                || (deviceStatus.valve2Leak ? (deviceStatus.valve2Leak.value === true ? deviceStatus.valve2Leak.value : false) : false)
                || (deviceStatus.valve1Opened ? (deviceStatus.valve1Opened.value === true ? deviceStatus.valve1Opened.value : false) : false)
                || (deviceStatus.valve2Opened ? (deviceStatus.valve2Opened.value === true ? deviceStatus.valve2Opened.value : false) : false)
                || (deviceStatus.valve1Closed ? (deviceStatus.valve1Closed.value === true ? deviceStatus.valve1Closed.value : false) : false)
                || (deviceStatus.valve2Closed ? (deviceStatus.valve2Closed.value === true ? deviceStatus.valve2Closed.value : false) : false)
                || (deviceStatus.batteryLow ? (deviceStatus.batteryLow.value ? deviceStatus.batteryLow.value : false) : false));
        } else {
            return false;
        }
    }

    function chooseMeterId(device, waterType) {
        if (device.meter1WaterType === waterType) {
            return device.meter1Id;
        } else if (device.meter2WaterType === waterType) {
            return device.meter2Id;
        }
        return null;
    }

    function chooseMeterSn(device, waterType) {
        if (device.meter1WaterType === waterType) {
            return device.meter1Sn;
        } else if (device.meter2WaterType === waterType) {
            return device.meter2Sn;
        }
        return null;
    }

    function onDownload(selection, downloadType) {
        const date = until ? until : new Date;
        const dateStr = moment(date).format("YYYY-MM-DD-HH-mm-ss");
        if (downloadType === "FILA") {
            const columns = [
                "Nazwa",
                "ID klienta",
                "Adres klienta",
                "ID licznika ZW",
                "ID licznika CW",
                "SN licznika ZW",
                "SN licznika CW",
                "Data",
                "Stan ZW (m3)",
                "Stan CW (m3)",
                "Podłączenie ZW",
                "Podłączenie CW",
                "Odłączenie ZW",
                "Odłączenie CW",
                "Przekroczenie dawki ZW",
                "Przekroczenie dawki CW",
                "Pęknięcie wężyka ZW",
                "Pęknięcie wężyka CW",
                "Przeciek ZW",
                "Przeciek CW",
                "Nieszczelność zaworu ZW",
                "Nieszczelność zaworu CW",
                "Otwarcie zaworu ZW",
                "Otwarcie zaworu CW",
                "Zamknięcie zaworu ZW",
                "Zamknięcie zaworu CW",
                "Słaba bateria",
                "Napięcie baterii (V)",
                "Opis"
            ];

            const rows = Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                const statuses = deviceStatuses.get(device.id);
                return [
                    device.name,
                    device.clientId,
                    device.clientAddress,
                    chooseMeterId(device, "COLD"),
                    chooseMeterId(device, "HOT"),
                    chooseMeterSn(device, "COLD"),
                    chooseMeterSn(device, "HOT"),
                    moment(date).format("YYYY-MM-DD"), //(statuses && statuses[0]) ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "HOT") : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryLow ? "tak" : null) : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryVoltage ? statuses[0].batteryVoltage : null) : null,
                    device.description
                ];
            });

            const csv = convertArrayToCSV(rows, {
                header: columns,
                separator: ','
            });
            const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "metering-fila-" + dateStr + ".csv");
        } else if (downloadType === "FILA_ZIEBICE") {
            const columns = [
                "Nazwa",
                "ID klienta",
                "Adres klienta",
                "ID licznika ZW",
                "ID licznika CW",
                "Czas",
                "Stan ZW (m3)",
                "Stan CW (m3)",
                "Podłączenie ZW",
                "Podłączenie CW",
                "Odłączenie ZW",
                "Odłączenie CW",
                "Przekroczenie dawki ZW",
                "Przekroczenie dawki CW",
                "Pęknięcie wężyka ZW",
                "Pęknięcie wężyka CW",
                "Przeciek ZW",
                "Przeciek CW",
                "Nieszczelność zaworu ZW",
                "Nieszczelność zaworu CW",
                "Otwarcie zaworu ZW",
                "Otwarcie zaworu CW",
                "Zamknięcie zaworu ZW",
                "Zamknięcie zaworu CW",
                "Słaba bateria",
                "Napięcie baterii (V)",
                "Opis"
            ];

            const rows = Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                const statuses = deviceStatuses.get(device.id);
                const meterColdId = chooseMeterId(device, "COLD");
                const meterHotId = chooseMeterId(device, "HOT");
                const meterColdSn = chooseMeterSn(device, "COLD");
                const meterHotSn = chooseMeterSn(device, "HOT");
                return [
                    device.name,
                    device.clientId,
                    device.clientAddress,
                    (meterColdSn != null && meterColdSn !== "") ? meterColdSn : meterColdId,
                    (meterHotSn != null && meterHotSn !== "") ? meterHotSn : meterHotId,
                    (statuses && statuses[0]) ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "HOT") : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryLow ? "tak" : null) : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryVoltage ? statuses[0].batteryVoltage : null) : null,
                    device.description
                ];
            });

            const csv = convertArrayToCSV(rows, {
                header: columns,
                separator: ','
            });
            const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "metering-fila-ziebice" + dateStr + ".csv");
        } else if (downloadType === "TBS_PULTUSK") {
            const columns = [
                "Nazwa",
                "Adres klienta",
                "ID licznika ZW",
                "ID licznika CW",
                "Stan ZW (m3)",
                "Stan CW (m3)"
            ];

            const rows = Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                const statuses = deviceStatuses.get(device.id);
                const meterColdId = chooseMeterId(device, "COLD");
                const meterHotId = chooseMeterId(device, "HOT");
                const meterColdSn = chooseMeterSn(device, "COLD");
                const meterHotSn = chooseMeterSn(device, "HOT");
                return [
                    device.name,
                    device.clientId,
                    device.clientAddress,
                    (meterColdSn != null && meterColdSn !== "") ? meterColdSn : meterColdId,
                    (meterHotSn != null && meterHotSn !== "") ? meterHotSn : meterHotId,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null
                ];
            });

            const csv = convertArrayToCSV(rows, {
                header: columns,
                separator: ','
            });
            const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "metering-tbs-pultusk-" + dateStr + ".csv");
        } else if (downloadType === "SKARSZEWIANKA") {
            const columns = [
                "Numer_Licznika",
                "Data_Odczytu",
                "Odczyt"
            ];

            function rows(waterType) {
                return Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                    const statuses = deviceStatuses.get(device.id);
                    const meterId = chooseMeterId(device, waterType);
                    const meterSn = chooseMeterSn(device, waterType);
                    return [
                        (meterSn != null && meterSn !== "") ? meterSn : meterId,
                        (statuses && statuses[0]) ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
                        statuses && statuses.length > 0 ? calculateState(device, statuses[0], waterType).value : null
                    ];
                });
            }

            const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows("COLD").concat(rows("HOT"))));
            const new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "ODCZYTY_PODLICZNIKÓW");
            const a = XLSX.write(new_workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
            saveAs(new Blob([a], { type: "application/octet-stream" }), "metering-skarszewianka-" + dateStr + ".xlsx");
        } else if (downloadType === "BEWIT") {
            const columns = [
                "Numer licznika",
                "Stan licznika",
                "Data odczytu"
            ];

            function rows(waterType) {
                return Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                    const statuses = deviceStatuses.get(device.id);
                    const meterId = chooseMeterId(device, waterType);
                    const meterSn = chooseMeterSn(device, waterType);
                    return [
                        (meterSn != null && meterSn !== "") ? meterSn : meterId,
                        (statuses && statuses[0]) ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
                        statuses && statuses.length > 0 ? calculateState(device, statuses[0], waterType).value : null
                    ];
                });
            }

            const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows("COLD").concat(rows("HOT"))));
            const new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering BeWit");
            const a = XLSX.write(new_workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
            saveAs(new Blob([a], { type: "application/octet-stream" }), "metering-bewit-" + dateStr + ".xlsx");
        } else if (downloadType === "GW_MAX") {
            const xmlDoc = document.implementation.createDocument(null, "Odczyty");
            Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).forEach(device => {
                const statuses = deviceStatuses.get(device.id);
                if (statuses && statuses.length > 0) {
                    const status = statuses[0];

                    if (status.meter1Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = status.meter1Index.value / 1000;
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }

                    if (status.meter2Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = status.meter2Index.value / 1000;
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }
                }
            })
            const serializer = new XMLSerializer();

            const blob = new Blob(("<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>" + serializer.serializeToString(xmlDoc)).split(), { type: "text/xml;charset=utf-8;" });
            saveAs(blob, "metering-gw-max-" + dateStr + ".xml");
        } else if (downloadType === "GW_MAX_DARLOWO") {
            const xmlDoc = document.implementation.createDocument(null, "Odczyty");
            Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).forEach(device => {
                const statuses = deviceStatuses.get(device.id);
                if (statuses && statuses.length > 0) {
                    const status = statuses[0];

                    if (status.meter1Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = Math.round(status.meter1Index.value / 1000);
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }

                    if (status.meter2Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = Math.round(status.meter2Index.value / 1000);
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }
                }
            })
            const serializer = new XMLSerializer();

            const blob = new Blob(("<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>" + serializer.serializeToString(xmlDoc)).split(), { type: "text/xml;charset=utf-8;" });
            saveAs(blob, "metering-gw-max-darlowo-" + dateStr + ".xml");
        } else if (downloadType === "GW_MAX_KISIELICE") {
            const xmlDoc = document.implementation.createDocument(null, "Odczyty");
            Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).forEach(device => {
                const statuses = deviceStatuses.get(device.id);
                if (statuses && statuses.length > 0) {
                    const status = statuses[0];

                    if (status.meter1Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = status.meter1Index.value / 1000;
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }

                    if (status.meter2Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = status.meter2Index.value / 1000;
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }
                }
            })
            const serializer = new XMLSerializer();

            const blob = new Blob([iconv.encode("<?xml version=\"1.0\" encoding=\"Windows-1250\" standalone=\"no\"?>" + serializer.serializeToString(xmlDoc), "win1250", {})], { type: "text/xml;charset=Windows-1250;" });

            saveAs(blob, "metering-gw-max-kisielice-" + dateStr + ".xml");
        } else if (downloadType === "WOD_KAN") {
            const xmlDoc = document.implementation.createDocument(null, "RECORDS");
            Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).forEach(device => {
                const statuses = deviceStatuses.get(device.id);
                if (statuses && statuses.length > 0) {
                    const status = statuses[0];

                    if (status.meter1Index) {
                        const root = xmlDoc.createElement("RECORD");

                        const row = xmlDoc.createElement("ROW");
                        row.setAttribute("NumLicznik", (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id)
                        row.setAttribute("DataOdczytu", moment(status.time).format("YYYY-MM-DD"))
                        row.setAttribute("StanLicznika", status.meter1Index.value / 1000)
                        row.textContent = device.meter1Id;
                        root.appendChild(row);

                        xmlDoc.documentElement.appendChild(root);
                    }

                    if (status.meter2Index) {
                        const root = xmlDoc.createElement("RECORD");

                        const row = xmlDoc.createElement("ROW");
                        row.setAttribute("NumLicznik", (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id)
                        row.setAttribute("DataOdczytu", moment(status.time).format("YYYY-MM-DD"))
                        row.setAttribute("StanLicznika", status.meter2Index.value / 1000)
                        row.textContent = device.meter2Id;
                        root.appendChild(row);

                        xmlDoc.documentElement.appendChild(root);
                    }
                }
            })
            const serializer = new XMLSerializer();

            const blob = new Blob(("<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>" + serializer.serializeToString(xmlDoc)).split(), { type: "text/xml;charset=utf-8;" });
            saveAs(blob, "metering-wod-kan-" + dateStr + ".xml");
        } else if (downloadType === "SYSTEMEG") {
            const columns = [
                "Numer licznika",
                "Numer ewidencyjny licznika",
                "Data odczytu",
                "Wartość odczytu"
            ];

            function rows(waterType) {
                return Array.from(devices.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                    const statuses = deviceStatuses.get(device.id);
                    return [
                        chooseMeterSn(device, waterType),
                        chooseMeterId(device, waterType),
                        (statuses && statuses[0]) ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
                        statuses && statuses.length > 0 ? calculateState(device, statuses[0], waterType).value : null
                    ];
                });
            }

            const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows("COLD").concat(rows("HOT"))));
            const new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering SYSTEmEG");
            const a = XLSX.write(new_workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
            saveAs(new Blob([a], { type: "application/octet-stream" }), "metering-systemeg-" + dateStr + ".xlsx");
        }
    }

    function calculateLastLorawanDr(deviceStatuses) {
        return deviceStatuses
    }

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Urządzenia" columns={
                        [
                            {
                                id: 'id',
                                numeric: false,
                                disablePadding: false,
                                label: 'ID',
                                render: (row) => (<Link className={classes.link}
                                    to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.id}</Link>)
                            },
                            {
                                id: 'name',
                                numeric: false,
                                disablePadding: false,
                                label: 'Nazwa',
                                render: (row) => (<Link className={classes.link}
                                    to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>)
                            },
                            { id: 'collectorId', numeric: false, disablePadding: false, label: 'ID inkasenta' },
                            { id: 'clientId', numeric: false, disablePadding: false, label: 'ID klienta' },
                            { id: 'clientAddress', numeric: false, disablePadding: false, label: 'Adres klienta' },
                            { id: 'meterIdCold', numeric: false, disablePadding: false, label: 'ID licznika ZW' },
                            { id: 'meterIdHot', numeric: false, disablePadding: false, label: 'ID licznika CW' },
                            { id: 'meterSnCold', numeric: false, disablePadding: false, label: 'SN licznika ZW' },
                            { id: 'meterSnHot', numeric: false, disablePadding: false, label: 'SN licznika CW' },
                            { id: 'description', numeric: false, disablePadding: false, label: 'Opis' }
                        ]
                    } initialRowsById={new Map(Array.from(devices.values()).map(device => {
                        const readingStatuses = deviceStatuses.get(device.id) ? Array.from(deviceStatuses.get(device.id)).filter(value => value.isReading) : [];
                        const alarmStatuses = deviceStatuses.get(device.id) ? Array.from(deviceStatuses.get(device.id)).filter(value => value.isAlarm) : [];

                        return [device.id, {
                            id: device.id,
                            name: device.name,
                            collectorId: device.collectorId,
                            clientId: device.clientId,
                            clientAddress: device.clientAddress,
                            meterIdCold: chooseMeterId(device, "COLD"),
                            meterIdHot: chooseMeterId(device, "HOT"),
                            meterSnCold: chooseMeterSn(device, "COLD"),
                            meterSnHot: chooseMeterSn(device, "HOT"),
                            description: device.description
                        }];
                    }))} initialOrderBy="name"
                        initialOrder="asc"
                        //selectionEnabled
                        //addDeleteEnabled
                        addUri={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/create?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}
                        //onDelete={onDelete}
                        filterEnabled
                        sortingEnabled
                        className={classes.richTable}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}