import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import RichTable from "./RichTable";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    item: {
        height: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
        lineHeight: "1rem"
    },
}));

export default function OrganizationGroups({organizationId, since, until, groups, onDelete, admin}) {
    const classes = useStyles();

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} className={classes.item}>
                    <RichTable name="Instalacje" columns={
                        [
                            {
                                id: 'name',
                                numeric: false,
                                disablePadding: false,
                                label: 'Nazwa',
                                render: (row) => (<Link className={classes.link}
                                                        to={"/organizations/" + organizationId + "/groups/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>)
                            },
                            {id: 'id', numeric: false, disablePadding: false, label: 'ID', render: (row) => row.id}
                        ]
                    } initialRowsById={new Map(Array.from(groups.values()).map(group => {
                        return [group.id, {
                            name: group.name,
                            id: group.id
                        }];
                    }))} initialOrderBy="name"
                               initialOrder="asc"
                               selectionEnabled
                               addDeleteEnabled
                               addUri={"/organizations/" + organizationId + "/groups/create?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}
                               onDelete={onDelete}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}