export default function GwInfo(
    gwId,
    time,
    rssi,
    rsrp,
    rsrq,
    snr,
    sinr,
    position) {
    this.gwId = gwId;
    this.time = time;
    this.rssi = rssi;
    this.rsrp = rsrp;
    this.rsrq = rsrq;
    this.snr = snr;
    this.sinr = sinr;
    this.position = position;
}

GwInfo.fromJson = function (json) {
    return new GwInfo(
        json.gwId,
        new Date(Date.parse(json.time)),
        json.rssi,
        json.rsrp,
        json.rsrq,
        json.snr,
        json.sinr,
        json.position);
};