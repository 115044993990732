import TimedValue from "./TimedValue";
import { useLocation } from "react-router-dom";

export function pairwise(arr) {
    return arr.reduce(function (result, value, index, array) {
        if (index % 2 === 0)
            result.push(array.slice(index, index + 2));
        return result;
    }, []);
}

export function sliding(arr, size) {
    if (size > arr.length) {
        return arr;
    }
    let result = [];
    let lastWindow = arr.length - size;
    for (let i = 0; i <= lastWindow; i += 1) {
        result.push(arr.slice(i, i + size));
    }
    return result;
}

export function calculateActive(device, waterType) {
    let active = false;

    if (waterType && device) {
        if (device.meter1WaterType === waterType && device.meter1Active) {
            active = true;
        } else if (device.meter2WaterType === waterType && device.meter2Active) {
            active = true;
        }
    }

    return active;
}

export function calculateState(device, status, waterType) {
    let time1 = null;
    let time2 = null;
    let state1 = null;
    let state2 = null;

    if (status && waterType && device) {
        if (device.meter1WaterType === waterType && status.meter1Index) {
            time1 = status.meter1Index.time;
            state1 = (status.meter1Index.value * device.meter1Multiplier + device.meter1InitialIndex - device.meter1InternalInitialIndex) / 1000;
        }

        if (device.meter2WaterType === waterType && status.meter2Index) {
            time2 = status.meter2Index.time;
            state2 = (status.meter2Index.value * device.meter2Multiplier + device.meter2InitialIndex - device.meter2InternalInitialIndex) / 1000;
        }
    }

    let stateSum = state1 == null && state2 == null ? null : state1 + state2;

    return new TimedValue((time1 !== null) ? time1 : ((time2 !== null) ? time2 : status ? status.time : null), (stateSum !== null) ? parseFloat(stateSum.toFixed(3)) : null);
}

export function calculateUsage(device, startStatus, endStatus, waterType) {
    let time1 = null;
    let time2 = null;

    let usage1 = null;
    let usage2 = null;

    if (startStatus && endStatus && waterType && device) {
        if (device.meter1WaterType === waterType && startStatus.meter1Index && endStatus.meter1Index) {
            time1 = endStatus.meter1Index.time;
            usage1 = (endStatus.meter1Index.value * device.meter1Multiplier - startStatus.meter1Index.value * device.meter1Multiplier) / 1000;
        }

        if (device.meter2WaterType === waterType && startStatus.meter2Index && endStatus.meter2Index) {
            time2 = endStatus.meter2Index.time;
            usage2 = (endStatus.meter2Index.value * device.meter2Multiplier - startStatus.meter2Index.value * device.meter2Multiplier) / 1000;
        }
    }

    let usageSum = usage1 + usage2;

    return new TimedValue((time1 !== null) ? time1 : time2, (usageSum !== null) ? parseFloat(usageSum) : null);
}

export function calculateTotalUsage(waterType, since, until, devicesById, groupDeviceAttributesByDeviceId, deviceStatusesByDeviceId) {
    let inState = 0;
    let outState = 0;
    let can = true;

    Array.from(devicesById.values()).forEach(device => {
        const deviceStatuses = deviceStatusesByDeviceId.get(device.id) ? deviceStatusesByDeviceId.get(device.id).filter(status => status.isReading) : [];

        if (deviceStatuses && deviceStatuses.length > 1) {
            if (device.meter1WaterType === waterType && deviceStatuses[0].meter1Index) {
                outState = outState + ((deviceStatuses[0].meter1Index.value * device.meter1Multiplier - deviceStatuses[deviceStatuses.length - 1].meter1Index.value * device.meter1Multiplier) / 1000);
            }
            if (device.meter2WaterType === waterType && deviceStatuses[0].meter2Index) {
                outState = outState + ((deviceStatuses[0].meter2Index.value * device.meter1Multiplier - deviceStatuses[deviceStatuses.length - 1].meter2Index.value * device.meter1Multiplier) / 1000);
            }
        } else {
            can = false;
        }
    });

    return {
        value: outState,
        isComplete: can
    };
}

export function calculateTime(groupDeviceStatuses) {
    const a = Array.from(groupDeviceStatuses.values())
        .map(value => value.length > 0 ? value[0] : null)
        .sort((a, b) => b.time - a.time)[0];
    return a ? a.time : null;
}

export function loraDataRateToValue(dataRate) {
    let value = null;
    switch (dataRate) {
        case "LORA_DR0":
            value = 0;
            break;
        case "LORA_DR1":
            value = 1;
            break;
        case "LORA_DR2":
            value = 2;
            break;
        case "LORA_DR3":
            value = 3;
            break;
        case "LORA_DR4":
            value = 4;
            break;
        case "LORA_DR5":
            value = 5;
            break;
        case "LORA_DR6":
            value = 6;
            break;
        case "LORA_DR7":
            value = 7;
            break;
        case "LORA_DR8":
            value = 8;
            break;
        case "LORA_DR9":
            value = 9;
            break;
        case "LORA_DR10":
            value = 10;
            break;
        case "LORA_DR11":
            value = 11;
            break;
        case "LORA_DR12":
            value = 12;
            break;
        case "LORA_DR13":
            value = 13;
            break;
        case "LORA_DR14":
            value = 14;
            break;
        case "LORA_DR15":
            value = 15;
            break;
        default:
            value = null;
    }
    return value;
}

export function stripTrailingSlash(str) {
    if (str.substr(-1) === '/') {
        return str.substr(0, str.length - 1);
    }
    return str;
}

export function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function avg(values) {
    const total = values.reduce((acc, c) => acc + c, 0);
    return total / values.length;
}

export function sum(value) {
    return value.reduce((acc, c) => acc + c, 0);
}

export function roundTimeToMinutes(date, minutes) {
    const p = minutes * 60 * 1000; // milliseconds in an hour
    return new Date(Math.round(date.getTime() / p) * p);
}

export function truncateTimeToHour(date) {
    let updatedDate = new Date(date.getTime());
    updatedDate.setMinutes(0);
    updatedDate.setSeconds(0);
    updatedDate.setMilliseconds(0);
    return updatedDate;
}

export function truncateTimeToDay(date) {
    let updatedDate = new Date(date.getTime());
    updatedDate.setHours(0);
    updatedDate.setMinutes(0);
    updatedDate.setSeconds(0);
    updatedDate.setMilliseconds(0);
    return updatedDate;
}

export function round(num, precision) {
    if (num !== null) {
        const factor = Math.pow(10, precision !== null ? precision : 0);
        return Math.round(num * factor) / factor;
    } else {
        return null;
    };
}

export function useURLSearchParams() {
    return new URLSearchParams(useLocation().search);
}

export function uuidv4() {
    return (`${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function calculateBalance(waterType, since, until, devicesById, groupDeviceAttributesByDeviceId, deviceStatusesByDeviceId) {
    let inState = 0;
    let outState = 0;
    let can = true;

    Array.from(devicesById.values()).forEach(device => {
        const groupDeviceAttributes = groupDeviceAttributesByDeviceId.get(device.id);
        //const deviceStatuses = deviceStatusesByDeviceId.get(device.id) ? deviceStatusesByDeviceId.get(device.id).filter(status => status.isReading && (truncateTimeToDay(status.time).getTime() === truncateTimeToDay(until ? until : new Date()).getTime() || truncateTimeToDay(status.time).getTime() === truncateTimeToDay(since).getTime())) : [];
        const deviceStatuses = deviceStatusesByDeviceId.get(device.id) ? deviceStatusesByDeviceId.get(device.id).filter(status => status.isReading) : [];

        if (deviceStatuses && deviceStatuses.length > 1) {
            if (groupDeviceAttributes.meter1BalanceWaterType === waterType && groupDeviceAttributes.meter1BalanceRole === "MAIN" && deviceStatuses[0].meter1Index) {
                inState = inState + (((deviceStatuses[0].meter1Index.value * device.meter1Multiplier) - (deviceStatuses[deviceStatuses.length - 1].meter1Index.value * device.meter1Multiplier)) / 1000);
            }
            if (groupDeviceAttributes.meter2BalanceWaterType === waterType && groupDeviceAttributes.meter2BalanceRole === "MAIN" && deviceStatuses[0].meter2Index) {
                inState = inState + (((deviceStatuses[0].meter2Index.value * device.meter2Multiplier) - (deviceStatuses[deviceStatuses.length - 1].meter2Index.value * device.meter2Multiplier)) / 1000);
            }

            if (groupDeviceAttributes.meter1BalanceWaterType === waterType && groupDeviceAttributes.meter1BalanceRole === "SUBMETER" && deviceStatuses[0].meter1Index) {
                outState = outState + (((deviceStatuses[0].meter1Index.value * device.meter1Multiplier) - (deviceStatuses[deviceStatuses.length - 1].meter1Index.value * device.meter1Multiplier)) / 1000);
            }
            if (groupDeviceAttributes.meter2BalanceWaterType === waterType && groupDeviceAttributes.meter2BalanceRole === "SUBMETER" && deviceStatuses[0].meter2Index) {
                outState = outState + (((deviceStatuses[0].meter2Index.value * device.meter2Multiplier) - (deviceStatuses[deviceStatuses.length - 1].meter2Index.value * device.meter2Multiplier)) / 1000);
            }
        } else {
            can = false;
        }
    });

    //return can ? ((outState - inState) / inState) * 100 : null;
    return {
        value: inState === 0 ? null : ((outState - inState) / inState) * 100,
        isComplete: can
    };
}

export function calculateBalanceColor(balance) {
    let balanceColor = "black";

    if (!balance.isComplete || !balance.value || isNaN(balance.value)) {
        balanceColor = "#0000008a";
    } else if (balance.value >= 10 || balance.value <= -10) {
        balanceColor = "red";
    } else if (balance.value >= 5 || balance.value <= -5) {
        balanceColor = "orange";
    } else if (balance.value <= 5 || balance.value >= -5) {
        balanceColor = "green";
    }

    return balanceColor;
}

export function chooseAlarm(device, time, alarm1, alarm2, waterType, notification) {
    if (device.meter1WaterType === waterType) {
        return alarm1 && alarm1.time.getTime() === time.getTime() ? (alarm1.value === true ? notification : null) : null;
    }
    if (device.meter2WaterType === waterType) {
        return alarm2 && alarm2.time.getTime() === time.getTime() ? (alarm2.value === true ? notification : null) : null;
    }
    return null;
}

export function chooseLastAlarm(device, statuses, alarmType1, alarmType2, waterType) {
    if (device.meter1WaterType === waterType) {
        const find = statuses.find(status => status.isAlarm && status[alarmType1]);
        return find ? (find[alarmType1].value === true ? "tak" : null) : null;
    }
    if (device.meter2WaterType === waterType) {
        const find = statuses.find(status => status.isAlarm && status[alarmType2]);
        return find ? (find[alarmType2].value === true ? "tak" : null) : null;
    }
    return null;
}

export function chooseDailyDose(device, time, alarm1, alarm2, waterType) {
    if (device.meter1WaterType === waterType) {
        return alarm1 && alarm1.time.getTime() === time.getTime() ? alarm1.value / 1000 : null;
    }
    if (device.meter2WaterType === waterType) {
        return alarm2 && alarm2.time.getTime() === time.getTime() ? alarm2.value / 1000 : null;
    }
    return null;
}

export function chooseInterImpulseTime(device, time, alarm1, alarm2, waterType) {
    if (device.meter1WaterType === waterType) {
        return alarm1 && alarm1.time.getTime() === time.getTime() ? alarm1.value.seconds() === 0 ? 0 : 36 / alarm1.value.seconds() : null;
    }
    if (device.meter2WaterType === waterType) {
        return alarm2 && alarm2.time.getTime() === time.getTime() ? alarm2.value.seconds() === 0 ? 0 : 36 / alarm2.value.seconds() : null;
    }
    return null;
}