import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { calculateState, loraDataRateToValue } from "./common";
import RichTable from "./RichTable";
import { gwIdToName } from "./gwIdToName";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    richTable: {
        whiteSpace: 'nowrap',
        height: 987
    }
}));

export default function DeviceReadings({ device, deviceStatuses }) {
    const classes = useStyles();

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Odczyty" columns={
                        [
                            { id: 'time', numeric: false, disablePadding: false, label: 'Czas' },
                            { id: 'recvTime', numeric: false, disablePadding: false, label: 'Czas odbioru' },
                            { id: 'seqNo', numeric: true, disablePadding: false, label: 'Numer sek.' },
                            { id: 'stateCold', numeric: true, disablePadding: false, label: 'Stan ZW (m3)' },
                            { id: 'stateHot', numeric: true, disablePadding: false, label: 'Stan CW (m3)' },
                            { id: 'stateHeat', numeric: true, disablePadding: false, label: 'Stan ciepła (GJ)' },
                            { id: 'loraDr', numeric: true, disablePadding: false, label: 'LoRa DR' },
                            { id: 'gw', numeric: false, disablePadding: false, label: 'Brama' },
                            { id: 'rssi', numeric: true, disablePadding: false, label: 'RSSI (dBm)' },
                            { id: 'snr', numeric: true, disablePadding: false, label: 'SNR (dB)' }
                        ]
                    } initialRowsById={new Map(deviceStatuses.filter(value => value.isReading).map(status => {
                        const id = moment(status.time).format("YYYY-MM-DD-HH-mm-ss");
                        return [id, {
                            time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                            recvTime: moment(status.rxMetadata.time).format("YYYY-MM-DD HH:mm:ss"),
                            seqNo: status.seqNo,
                            stateCold: calculateState(device, status, "COLD").value,
                            stateHot: calculateState(device, status, "HOT").value,
                            stateHeat: calculateState(device, status, "HEAT").value,
                            loraDr: loraDataRateToValue(status.rxMetadata.dataRate),
                            gw: gwIdToName[status.rxMetadata.gwInfo[0].gwId] ? gwIdToName[status.rxMetadata.gwInfo[0].gwId] : status.rxMetadata.gwInfo[0].gwId,
                            rssi: status.rxMetadata.gwInfo[0].rssi,
                            snr: status.rxMetadata.gwInfo[0].snr,
                        }];
                    }))} initialOrderBy="time" initialOrder="desc" selectionEnabled downloadEnabled filterEnabled sortingEnabled className={classes.richTable} />
                </Grid>
            </Grid>
        </Container>
    );
}