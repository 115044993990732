import * as moment from "moment";
import TimeRange from "./TimeRange";

export default function Device(
    id,
    name,
    description,
    notificationMsisdns,
    notificationEmailAddresses,
    collectorId,
    clientId,
    clientAddress,
    meter1Active,
    meter2Active,
    meter3Active,
    meter1Id,
    meter2Id,
    meter3Id,
    meter1Sn,
    meter2Sn,
    meter3Sn,
    meter1WaterType,
    meter2WaterType,
    meter3WaterType,
    meter1InitialIndex,
    meter2InitialIndex,
    meter3InitialIndex,
    meter1InternalInitialIndex,
    meter2InternalInitialIndex,
    meter3InternalInitialIndex,
    meter1Multiplier,
    meter2Multiplier,
    meter3Multiplier,
    valve1Active,
    valve2Active,
    valve3Active,
    valve1Open,
    valve2Open,
    valve3Open,
    hoseCrackCoefficient,
    dailyDoseCoefficient,
    interImpulseTime,
    dailyDose,
    measurementInterval,
    txTimeRange,
    maxTxDelay,
    lorawanDevEui,
    lorawanNwkKey,
    lorawanAppKey,
    timeZoneOffset,
    type) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.notificationMsisdns = notificationMsisdns;
    this.notificationEmailAddresses = notificationEmailAddresses;
    this.collectorId = collectorId;
    this.clientId = clientId;
    this.clientAddress = clientAddress;
    this.meter1Active = meter1Active;
    this.meter2Active = meter2Active;
    this.meter3Active = meter3Active;
    this.meter1Id = meter1Id;
    this.meter2Id = meter2Id;
    this.meter3Id = meter3Id;
    this.meter1Sn = meter1Sn;
    this.meter2Sn = meter2Sn;
    this.meter3Sn = meter3Sn;
    this.meter1WaterType = meter1WaterType;
    this.meter2WaterType = meter2WaterType;
    this.meter3WaterType = meter3WaterType;
    this.meter1InitialIndex = meter1InitialIndex;
    this.meter2InitialIndex = meter2InitialIndex;
    this.meter3InitialIndex = meter3InitialIndex;
    this.meter1InternalInitialIndex = meter1InternalInitialIndex;
    this.meter2InternalInitialIndex = meter2InternalInitialIndex;
    this.meter3InternalInitialIndex = meter3InternalInitialIndex;
    this.meter1Multiplier = meter1Multiplier;
    this.meter2Multiplier = meter2Multiplier;
    this.meter3Multiplier = meter3Multiplier;
    this.valve1Active = valve1Active;
    this.valve2Active = valve2Active;
    this.valve3Active = valve3Active;
    this.valve1Open = valve1Open;
    this.valve2Open = valve2Open;
    this.valve3Open = valve3Open;
    this.hoseCrackCoefficient = hoseCrackCoefficient;
    this.dailyDoseCoefficient = dailyDoseCoefficient;
    this.interImpulseTime = interImpulseTime;
    this.dailyDose = dailyDose;
    this.measurementInterval = measurementInterval;
    this.txTimeRange = txTimeRange;
    this.maxTxDelay = maxTxDelay;
    this.lorawanDevEui = lorawanDevEui;
    this.lorawanNwkKey = lorawanNwkKey;
    this.lorawanAppKey = lorawanAppKey;
    this.timeZoneOffset = timeZoneOffset;
    this.type = type;

    this.toJson = function () {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            notificationMsisdns: this.notificationMsisdns,
            notificationEmailAddresses: this.notificationEmailAddresses,
            collectorId: this.collectorId,
            clientId: this.clientId,
            clientAddress: this.clientAddress,
            meter1Active: this.meter1Active,
            meter2Active: this.meter2Active,
            meter3Active: this.meter3Active,
            meter1Id: this.meter1Id,
            meter2Id: this.meter2Id,
            meter3Id: this.meter3Id,
            meter1Sn: this.meter1Sn,
            meter2Sn: this.meter2Sn,
            meter3Sn: this.meter3Sn,
            meter1WaterType: this.meter1WaterType,
            meter2WaterType: this.meter2WaterType,
            meter3WaterType: this.meter3WaterType,
            meter1InitialIndex: this.meter1InitialIndex,
            meter2InitialIndex: this.meter2InitialIndex,
            meter3InitialIndex: this.meter3InitialIndex,
            meter1InternalInitialIndex: this.meter1InternalInitialIndex,
            meter2InternalInitialIndex: this.meter2InternalInitialIndex,
            meter3InternalInitialIndex: this.meter3InternalInitialIndex,
            meter1Multiplier: this.meter1Multiplier,
            meter2Multiplier: this.meter2Multiplier,
            meter3Multiplier: this.meter3Multiplier,
            valve1Active: this.valve1Active,
            valve2Active: this.valve2Active,
            valve3Active: this.valve3Active,
            valve1Open: this.valve1Open,
            valve2Open: this.valve2Open,
            valve3Open: this.valve3Open,
            hoseCrackCoefficient: this.hoseCrackCoefficient,
            dailyDoseCoefficient: this.dailyDoseCoefficient,
            interImpulseTime: this.interImpulseTime.toISOString(),
            dailyDose: this.dailyDose,
            measurementInterval: this.measurementInterval.toISOString(),
            txTimeRange: this.txTimeRange.toJson(),
            maxTxDelay: this.maxTxDelay ? this.maxTxDelay.toISOString() : null,
            lorawanDevEui: this.lorawanDevEui,
            lorawanNwkKey: this.lorawanNwkKey,
            lorawanAppKey: this.lorawanAppKey,
            timeZoneOffset: this.timeZoneOffset,
            type: this.type
        };
    }
}

Device.fromJson = function (json) {
    return new Device(
        json.id,
        json.name,
        json.description,
        json.notificationMsisdns,
        json.notificationEmailAddresses,
        json.collectorId,
        json.clientId,
        json.clientAddress,
        json.meter1Active,
        json.meter2Active,
        json.meter3Active,
        json.meter1Id,
        json.meter2Id,
        json.meter3Id,
        json.meter1Sn,
        json.meter2Sn,
        json.meter3Sn,
        json.meter1WaterType,
        json.meter2WaterType,
        json.meter3WaterType,
        json.meter1InitialIndex,
        json.meter2InitialIndex,
        json.meter3InitialIndex,
        json.meter1InternalInitialIndex,
        json.meter2InternalInitialIndex,
        json.meter3InternalInitialIndex,
        json.meter1Multiplier,
        json.meter2Multiplier,
        json.meter3Multiplier,
        json.valve1Active,
        json.valve2Active,
        json.valve3Active,
        json.valve1Open,
        json.valve2Open,
        json.valve3Open,
        json.hoseCrackCoefficient,
        json.dailyDoseCoefficient,
        json.interImpulseTime ? moment.duration(json.interImpulseTime) : null,
        json.dailyDose,
        moment.duration(json.measurementInterval),
        TimeRange.fromJson(json.txTimeRange),
        json.maxTxDelay ? moment.duration(json.maxTxDelay) : null,
        json.lorawanDevEui,
        json.lorawanNwkKey,
        json.lorawanAppKey,
        json.timeZoneOffset,
        json.type);
};