export default function TimedValue(time, value) {
    this.time = time;
    this.value = value;
}

TimedValue.fromJson = function(json) {
    if(json) {
        return new TimedValue(
            new Date(Date.parse(json.time)),
            json.value);
    } else return null;
};