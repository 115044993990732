import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TimedValuePanel from "./TimedValuePanel";
import TimedValue from "./TimedValue";
import { Lens } from "@material-ui/icons";
import {
    calculateTime,
    round,
    calculateBalanceColor,
    calculateBalance,
    calculateState,
    loraDataRateToValue,
    calculateUsage,
    chooseAlarm,
    chooseLastAlarm,
    chooseDailyDose,
    chooseInterImpulseTime, calculateActive, calculateTotalUsage
} from "./common";
import { Link } from "react-router-dom";
import moment from "moment";
import RichTable from "./RichTable";
import { convertArrayToCSV } from "convert-array-to-csv";
import XLSX from "xlsx";
import iconv from "iconv-lite";
import { saveAs } from "file-saver"
import { gwIdToName } from "./gwIdToName";
import {
    brzegDolnyUnisoft,
    chojnickieTbs,
    dabrowaTarnowskaSm,
    excel,
    gdanskHomesystemW,
    gdanskHomesystemC,
    gdanskKo,
    gdanskPoludnieZielonaGoraRtbs,
    gdanskPoludnieZielonaGoraRtbs2,
    gliwiceZbm, granit,
    jasloWiertnik,
    ketrzynZn,
    krasiczynGmina,
    mesznaSw,
    ozarowiceWodnik,
    pelplinPelkom,
    qnetLeczyce,
    systemeg,
    systemeg1,
    wegrow,
    ustroniePapirus, wilkowiceSw,
    wodaTrabkiWielkie,
    zbmBytom,
    ziebiceZwik,
    warszawaVictus,
    osmProbit,
    raciborzDomEk,
    czestochowaZnCtg,
    warszawaPolitechnika,
    wloszakowice,
    omZuk,
    gdanskEnergetyk,
    warszawaGgko,
    silesiaSlonie,
    bystrzycaKlodzka,
    pyskowice,
    dwikozy,
    brzezinyBsm,
    gdanskBizan,
    krosnoOdrzanskie,
    btbsBytow
} from "./downloads";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    panel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 144,
    },
    chartPanel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 233,
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
    },
    lastAlarmsTable: {
        whiteSpace: 'nowrap',
        height: 233
    },
    lastReadingsTable: {
        whiteSpace: 'nowrap',
        height: 233
    },
    devicesTable: {
        whiteSpace: 'nowrap',
        height: 610
    }
}));

export default function GroupDashboard({
    organizationId,
    groupId,
    since,
    until,
    devicesById,
    groupGroupDeviceAttributesByDeviceId,
    deviceStatusesByDeviceId,
    deviceStatuses
}) {
    const classes = useStyles();

    const [balanceCold, balanceHot] = React.useMemo(() => [calculateBalance("COLD", since, until, devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId), calculateBalance("HOT", since, until, devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId)], [devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId]);
    const [totalUsageCold, totalUsageHot] = React.useMemo(() => [calculateTotalUsage("COLD", since, until, devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId), calculateTotalUsage("HOT", since, until, devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId)], [devicesById, groupGroupDeviceAttributesByDeviceId, deviceStatusesByDeviceId]);
    const time = React.useMemo(() => calculateTime(deviceStatusesByDeviceId), [deviceStatusesByDeviceId]);

    function deviceHasAlarms(deviceStatus) {
        if (deviceStatus) {
            return ((deviceStatus.meter1Connected ? (deviceStatus.meter1Connected.value === true ? deviceStatus.meter1Connected.value : false) : false)
                || (deviceStatus.meter2Connected ? (deviceStatus.meter2Connected.value === true ? deviceStatus.meter2Connected.value : false) : false)
                || (deviceStatus.meter1Disconnected ? (deviceStatus.meter1Disconnected.value === true ? deviceStatus.meter1Disconnected.value : false) : false)
                || (deviceStatus.meter2Disconnected ? (deviceStatus.meter2Disconnected.value === true ? deviceStatus.meter2Disconnected.value : false) : false)
                || (deviceStatus.meter1DoseExceeded ? (deviceStatus.meter1DoseExceeded.value === true ? deviceStatus.meter1DoseExceeded.value : false) : false)
                || (deviceStatus.meter2DoseExceeded ? (deviceStatus.meter2DoseExceeded.value === true ? deviceStatus.meter2DoseExceeded.value : false) : false)
                || (deviceStatus.meter1HoseCracked ? (deviceStatus.meter1HoseCracked.value === true ? deviceStatus.meter1HoseCracked.value : false) : false)
                || (deviceStatus.meter2HoseCracked ? (deviceStatus.meter2HoseCracked.value === true ? deviceStatus.meter2HoseCracked.value : false) : false)
                || (deviceStatus.meter1Leak ? (deviceStatus.meter1Leak.value === true ? deviceStatus.meter1Leak.value : false) : false)
                || (deviceStatus.meter1Leak ? (deviceStatus.meter1Leak.value === true ? deviceStatus.meter1Leak.value : false) : false)
                || (deviceStatus.meter1MinUsageNotReached ? (deviceStatus.meter1MinUsageNotReached.value === true ? deviceStatus.meter1MinUsageNotReached.value : false) : false)
                || (deviceStatus.meter2MinUsageNotReached ? (deviceStatus.meter2MinUsageNotReached.value === true ? deviceStatus.meter2MinUsageNotReached.value : false) : false)
                || (deviceStatus.valve1Leak ? (deviceStatus.valve1Leak.value === true ? deviceStatus.valve1Leak.value : false) : false)
                || (deviceStatus.valve2Leak ? (deviceStatus.valve2Leak.value === true ? deviceStatus.valve2Leak.value : false) : false)
                || (deviceStatus.valve1Opened ? (deviceStatus.valve1Opened.value === true ? deviceStatus.valve1Opened.value : false) : false)
                || (deviceStatus.valve2Opened ? (deviceStatus.valve2Opened.value === true ? deviceStatus.valve2Opened.value : false) : false)
                || (deviceStatus.valve1Closed ? (deviceStatus.valve1Closed.value === true ? deviceStatus.valve1Closed.value : false) : false)
                || (deviceStatus.valve2Closed ? (deviceStatus.valve2Closed.value === true ? deviceStatus.valve2Closed.value : false) : false)
                || (deviceStatus.batteryLow ? (deviceStatus.batteryLow.value ? deviceStatus.batteryLow.value : false) : false));
        } else {
            return false;
        }
    }

    function chooseMeterId(device, waterType) {
        if (device.meter1WaterType === waterType) {
            return device.meter1Id;
        } else if (device.meter2WaterType === waterType) {
            return device.meter2Id;
        }
        return null;
    }

    function chooseMeterSn(device, waterType) {
        if (device.meter1WaterType === waterType) {
            return device.meter1Sn;
        } else if (device.meter2WaterType === waterType) {
            return device.meter2Sn;
        }
        return null;
    }

    function onDownload(selection, downloadType) {
        const date = until ? until : new Date;
        const dateStr = moment(date).format("YYYY-MM-DD-HH-mm-ss");
        if (downloadType === "EXCEL") {
            saveAs(excel(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        }
        else if (downloadType === "FILA") {
            const columns = [
                "Nazwa",
                "ID klienta",
                "Adres klienta",
                "ID licznika ZW",
                "ID licznika CW",
                "SN licznika ZW",
                "SN licznika CW",
                "Data",
                "Stan ZW (m3)",
                "Stan CW (m3)",
                "Podłączenie ZW",
                "Podłączenie CW",
                "Odłączenie ZW",
                "Odłączenie CW",
                "Przekroczenie dawki ZW",
                "Przekroczenie dawki CW",
                "Pęknięcie wężyka ZW",
                "Pęknięcie wężyka CW",
                "Przeciek ZW",
                "Przeciek CW",
                "Nieszczelność zaworu ZW",
                "Nieszczelność zaworu CW",
                "Otwarcie zaworu ZW",
                "Otwarcie zaworu CW",
                "Zamknięcie zaworu ZW",
                "Zamknięcie zaworu CW",
                "Słaba bateria",
                "Napięcie baterii (V)",
                "Opis"
            ];

            const rows = Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                const statuses = deviceStatusesByDeviceId.get(device.id);
                return [
                    device.name,
                    device.clientId,
                    device.clientAddress,
                    chooseMeterId(device, "COLD"),
                    chooseMeterId(device, "HOT"),
                    chooseMeterSn(device, "COLD"),
                    chooseMeterSn(device, "HOT"),
                    moment(date).format("YYYY-MM-DD"),
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "HOT") : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryLow ? "tak" : null) : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryVoltage ? statuses[0].batteryVoltage : null) : null,
                    device.description
                ];
            });

            const csv = convertArrayToCSV(rows, {
                header: columns,
                separator: ','
            });
            const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "metering-fila-" + dateStr + ".csv");
        } else if (downloadType === "FILA_ZUZYCIA") {
            const columns = [
                "Nazwa",
                "ID klienta",
                "Adres klienta",
                "ID licznika ZW",
                "ID licznika CW",
                "Czas",
                "Stan ZW (m3)",
                "Stan CW (m3)",
                "Zużycie ZW (m3)",
                "Zużycie CW (m3)",
                "Podłączenie ZW",
                "Podłączenie CW",
                "Odłączenie ZW",
                "Odłączenie CW",
                "Przekroczenie dawki ZW",
                "Przekroczenie dawki CW",
                "Pęknięcie wężyka ZW",
                "Pęknięcie wężyka CW",
                "Przeciek ZW",
                "Przeciek CW",
                "Nieszczelność zaworu ZW",
                "Nieszczelność zaworu CW",
                "Otwarcie zaworu ZW",
                "Otwarcie zaworu CW",
                "Zamknięcie zaworu ZW",
                "Zamknięcie zaworu CW",
                "Słaba bateria",
                "Napięcie baterii (V)",
                "Opis"
            ];

            const rows = Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                const statuses = deviceStatusesByDeviceId.get(device.id);
                const stateCold = statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null;
                const stateHot = statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null;
                const usageCold = statuses && statuses.length > 0 ? calculateUsage(device, statuses[statuses.length - 1], statuses[0], "COLD").value : null;
                const usageHot = statuses && statuses.length > 0 ? calculateUsage(device, statuses[statuses.length - 1], statuses[0], "HOT").value : null;
                return [
                    device.name,
                    device.clientId,
                    device.clientAddress,
                    chooseMeterId(device, "COLD"),
                    chooseMeterId(device, "HOT"),
                    (statuses && statuses[0]) ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
                    stateCold ? stateCold.toString().replace(/\./g, ',') : null,
                    stateHot ? stateHot.toString().replace(/\./g, ',') : null,
                    usageCold ? stateCold.toString().replace(/\./g, ',') : null,
                    usageHot ? stateCold.toString().replace(/\./g, ',') : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "HOT") : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryLow ? "tak" : null) : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryVoltage ? statuses[0].batteryVoltage : null) : null,
                    device.description
                ];
            });

            const csv = convertArrayToCSV(rows, {
                header: columns,
                separator: ';'
            });
            const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "metering-fila-" + dateStr + ".csv");
        } else if (downloadType === "FILA_ZIEBICE") {
            const columns = [
                "Nazwa",
                "ID klienta",
                "Adres klienta",
                "ID licznika ZW",
                "ID licznika CW",
                "Czas",
                "Stan ZW (m3)",
                "Stan CW (m3)",
                "Podłączenie ZW",
                "Podłączenie CW",
                "Odłączenie ZW",
                "Odłączenie CW",
                "Przekroczenie dawki ZW",
                "Przekroczenie dawki CW",
                "Pęknięcie wężyka ZW",
                "Pęknięcie wężyka CW",
                "Przeciek ZW",
                "Przeciek CW",
                "Nieszczelność zaworu ZW",
                "Nieszczelność zaworu CW",
                "Otwarcie zaworu ZW",
                "Otwarcie zaworu CW",
                "Zamknięcie zaworu ZW",
                "Zamknięcie zaworu CW",
                "Słaba bateria",
                "Napięcie baterii (V)",
                "Opis"
            ];

            const rows = Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                const statuses = deviceStatusesByDeviceId.get(device.id);
                const meterColdId = chooseMeterId(device, "COLD");
                const meterHotId = chooseMeterId(device, "HOT");
                const meterColdSn = chooseMeterSn(device, "COLD");
                const meterHotSn = chooseMeterSn(device, "HOT");
                return [
                    device.name,
                    device.clientId,
                    device.clientAddress,
                    (meterColdSn != null && meterColdSn !== "") ? meterColdSn : meterColdId,
                    (meterHotSn != null && meterHotSn !== "") ? meterHotSn : meterHotId,
                    (statuses && statuses[0]) ? moment(statuses[0].time).format("YYYY-MM-DD HH:mm:ss") : null,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Connected", "meter2Connected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Disconnected", "meter2Disconnected", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1DoseExceeded", "meter2DoseExceeded", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1HoseCracked", "meter2HoseCracked", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1Leak", "meter2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "meter1MinUsageNotReached", "meter2MinUsageNotReached", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Leak", "valve2Leak", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Opened", "valve2Opened", "HOT") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "COLD") : null,
                    statuses && statuses.length > 0 ? chooseLastAlarm(device, statuses, "valve1Closed", "valve2Closed", "HOT") : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryLow ? "tak" : null) : null,
                    statuses && statuses.length > 0 ? (statuses[0].batteryVoltage ? statuses[0].batteryVoltage : null) : null,
                    device.description
                ];
            });

            const csv = convertArrayToCSV(rows, {
                header: columns,
                separator: ','
            });
            const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "metering-fila-ziebice" + dateStr + ".csv");
        } else if (downloadType === "TBS_PULTUSK") {
            const columns = [
                "Nazwa",
                "Adres klienta",
                "ID licznika ZW",
                "ID licznika CW",
                "Stan ZW (m3)",
                "Stan CW (m3)"
            ];

            const rows = Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                const statuses = deviceStatusesByDeviceId.get(device.id);
                const meterColdId = chooseMeterId(device, "COLD");
                const meterHotId = chooseMeterId(device, "HOT");
                const meterColdSn = chooseMeterSn(device, "COLD");
                const meterHotSn = chooseMeterSn(device, "HOT");
                return [
                    device.name,
                    device.clientId,
                    device.clientAddress,
                    (meterColdSn != null && meterColdSn !== "") ? meterColdSn : meterColdId,
                    (meterHotSn != null && meterHotSn !== "") ? meterHotSn : meterHotId,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "COLD").value : null,
                    statuses && statuses.length > 0 ? calculateState(device, statuses[0], "HOT").value : null
                ];
            });

            const csv = convertArrayToCSV(rows, {
                header: columns,
                separator: ','
            });
            const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "metering-tbs-pultusk-" + dateStr + ".csv");
        } else if (downloadType === "SKARSZEWIANKA") {
            const columns = [
                "Numer_Licznika",
                "Data_Odczytu",
                "Odczyt",
                "Adres"
            ];

            function rows(waterType) {
                return Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                    const statuses = deviceStatusesByDeviceId.get(device.id);
                    const meterId = chooseMeterId(device, waterType);
                    const meterSn = chooseMeterSn(device, waterType);
                    return [
                        (meterSn != null && meterSn !== "") ? meterSn : meterId,
                        (statuses && statuses[0]) ? moment(statuses[0].time).format("YYYY-MM-DD") : null,
                        statuses && statuses.length > 0 ? calculateState(device, statuses[0], waterType).value : null,
                        device.clientAddress
                    ];
                });
            }

            const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows("COLD").concat(rows("HOT"))));
            const new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "ODCZYTY_PODLICZNIKÓW");
            const a = XLSX.write(new_workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
            saveAs(new Blob([a], { type: "application/octet-stream" }), "metering-skarszewianka-" + dateStr + ".xlsx");
        } else if (downloadType === "BEWIT") {
            const columns = [
                "Numer licznika",
                "Stan licznika",
                "Data odczytu"
            ];

            function rows(waterType) {
                return Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).map(device => {
                    const statuses = deviceStatusesByDeviceId.get(device.id);
                    const meterId = chooseMeterId(device, waterType);
                    const meterSn = chooseMeterSn(device, waterType);
                    return [
                        (meterSn != null && meterSn !== "") ? meterSn : meterId,
                        (statuses && statuses[0]) ? moment(statuses[0].time).format("DD.MM.YYYY") : null,
                        statuses && statuses.length > 0 ? calculateState(device, statuses[0], waterType).value : null
                    ];
                });
            }

            const worksheet = XLSX.utils.aoa_to_sheet([columns].concat(rows("COLD").concat(rows("HOT"))));
            const new_workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(new_workbook, worksheet, "Metering BeWit");
            const a = XLSX.write(new_workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
            saveAs(new Blob([a], { type: "application/octet-stream" }), "metering-bewit-" + dateStr + ".xlsx");
        } else if (downloadType === "GW_MAX") {
            const xmlDoc = document.implementation.createDocument(null, "Odczyty");
            Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).forEach(device => {
                const statuses = deviceStatusesByDeviceId.get(device.id);
                if (statuses && statuses.length > 0) {
                    const status = statuses[0];

                    if (status.meter1Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = calculateState(device, status, device.meter1WaterType).value;
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }

                    if (status.meter2Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = calculateState(device, status, device.meter2WaterType).value;
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }
                }
            })
            const serializer = new XMLSerializer();

            const blob = new Blob(("<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>" + serializer.serializeToString(xmlDoc)).split(), { type: "text/xml;charset=utf-8;" });
            saveAs(blob, "metering-gw-max-" + dateStr + ".xml");
        } else if (downloadType === "GW_MAX_DARLOWO") {
            const xmlDoc = document.implementation.createDocument(null, "Odczyty");
            Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).forEach(device => {
                const statuses = deviceStatusesByDeviceId.get(device.id);
                if (statuses && statuses.length > 0) {
                    const status = statuses[0];

                    if (status.meter1Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = Math.round(calculateState(device, status, device.meter1WaterType).value);
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }

                    if (status.meter2Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = Math.round(calculateState(device, status, device.meter2WaterType).value);
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }
                }
            })
            const serializer = new XMLSerializer();

            const blob = new Blob(("<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>" + serializer.serializeToString(xmlDoc)).split(), { type: "text/xml;charset=utf-8;" });
            saveAs(blob, "metering-gw-max-darlowo-" + dateStr + ".xml");
        } else if (downloadType === "GW_MAX_KISIELICE") {
            const xmlDoc = document.implementation.createDocument(null, "Odczyty");
            Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).forEach(device => {
                const statuses = deviceStatusesByDeviceId.get(device.id);
                if (statuses && statuses.length > 0) {
                    const status = statuses[0];

                    if (status.meter1Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = calculateState(device, status, device.meter1WaterType).value;
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }

                    if (status.meter2Index) {
                        const root = xmlDoc.createElement("Odczyt");

                        const nrWodomierz = xmlDoc.createElement("NrWodomierz");
                        nrWodomierz.textContent = (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id;
                        root.appendChild(nrWodomierz);

                        const data = xmlDoc.createElement("Data");
                        data.textContent = moment(status.time).format("YYYYMMDD");
                        root.appendChild(data);

                        const odczyt = xmlDoc.createElement("Odczyt");
                        odczyt.textContent = calculateState(device, status, device.meter2WaterType).value;
                        root.appendChild(odczyt);

                        xmlDoc.documentElement.appendChild(root);
                    }
                }
            })
            const serializer = new XMLSerializer();

            const blob = new Blob([iconv.encode("<?xml version=\"1.0\" encoding=\"Windows-1250\" standalone=\"no\"?>" + serializer.serializeToString(xmlDoc), "win1250", {})], { type: "text/xml;charset=Windows-1250;" });

            saveAs(blob, "metering-gw-max-kisielice-" + dateStr + ".xml");
        } else if (downloadType === "WOD_KAN") {
            const xmlDoc = document.implementation.createDocument(null, "RECORDS");
            Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).forEach(device => {
                const statuses = deviceStatusesByDeviceId.get(device.id);
                if (statuses && statuses.length > 0) {
                    const status = statuses[0];

                    if (status.meter1Index) {
                        const root = xmlDoc.createElement("RECORD");

                        const row = xmlDoc.createElement("ROW");
                        row.setAttribute("NumLicznik", (device.meter1Sn != null && device.meter1Sn !== "") ? device.meter1Sn : device.meter1Id)
                        row.setAttribute("DataOdczytu", moment(status.time).format("YYYY-MM-DD"))
                        row.setAttribute("StanLicznika", calculateState(device, status, device.meter1WaterType).value)
                        row.textContent = device.meter1Id;
                        root.appendChild(row);

                        xmlDoc.documentElement.appendChild(root);
                    }

                    if (status.meter2Index) {
                        const root = xmlDoc.createElement("RECORD");

                        const row = xmlDoc.createElement("ROW");
                        row.setAttribute("NumLicznik", (device.meter2Sn != null && device.meter2Sn !== "") ? device.meter2Sn : device.meter2Id)
                        row.setAttribute("DataOdczytu", moment(status.time).format("YYYY-MM-DD"))
                        row.setAttribute("StanLicznika", calculateState(device, status, device.meter2WaterType).value)
                        row.textContent = device.meter2Id;
                        root.appendChild(row);

                        xmlDoc.documentElement.appendChild(root);
                    }
                }
            })
            const serializer = new XMLSerializer();

            const blob = new Blob(("<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>" + serializer.serializeToString(xmlDoc)).split(), { type: "text/xml;charset=utf-8;" });
            saveAs(blob, "metering-wod-kan-" + dateStr + ".xml");
        } else if (downloadType === "SYSTEMEG") {
            saveAs(systemeg(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-systemeg-" + dateStr + ".xlsx");
        } else if (downloadType === "SYSTEMEG1") {
            saveAs(systemeg1(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-systemeg1-" + dateStr + ".xlsx");
        } else if (downloadType === "QNET_LECZYCE") {
            saveAs(qnetLeczyce(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "OZAROWICE_WODNIK") {
            saveAs(ozarowiceWodnik(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "ZBM_BYTOM") {
            saveAs(zbmBytom(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        }
        else if (downloadType === "MESZNA_SW") {
            saveAs(mesznaSw(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "GRANIT") {
            saveAs(granit(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "ODCZYTY_PODLICZNIKÓW.csv");
        } else if (downloadType === "WODA_TRABKI_WIELKIE") {
            saveAs(wodaTrabkiWielkie(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "TBS_BRZEZINY_PAPIRUS") {
            const columns = [
                "Id_klienta",
                "Numer wodomierza",
                "Odczyt historyczny"
            ];

            const rows = Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)).flatMap((device, index) => {
                const statuses = deviceStatusesByDeviceId.get(device.id);

                if (statuses && statuses.length > 0) {
                    const meter1 = calculateActive(device, "COLD") ? [
                        device.clientId,
                        chooseMeterId(device, "COLD"),
                        calculateState(device, statuses[0], "COLD").value.toString().replace(/\./g, ',')
                    ] : null;
                    const meter2 = calculateActive(device, "HOT") ? [
                        device.clientId,
                        chooseMeterId(device, "HOT"),
                        calculateState(device, statuses[0], "HOT").value.toString().replace(/\./g, ',')
                    ] : null;

                    if (meter1 && meter2) {
                        return [meter1, meter2];
                    } else if (meter1) {
                        return [meter1];
                    } else if (meter2) {
                        return [meter2];
                    } else {
                        return [];
                    }
                } else {
                    return [];
                }
            });

            const csv = convertArrayToCSV(rows, {
                header: columns,
                separator: ';'
            });
            const blob = new Blob(csv.split(), { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "metering-tbs-brzeziny-papirus-" + dateStr + ".csv");
        } else if (downloadType === "USTRONIE_PAPIRUS") {
            saveAs(ustroniePapirus(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "GDANSK_KO") {
            saveAs(gdanskKo(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        } else if (downloadType === "BRZEG_DOLNY_UNISOFT") {
            saveAs(brzegDolnyUnisoft(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-brzeg-dolny-unisoft-" + dateStr + ".csv");
        } else if (downloadType === "GDANSK_POLUDNIE_ZIELONA_GORA_RTBS") {
            saveAs(gdanskPoludnieZielonaGoraRtbs(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "GDANSK_POLUDNIE_ZIELONA_GORA_RTBS_2") {
            saveAs(gdanskPoludnieZielonaGoraRtbs2(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "GLIWICE_ZBM") {
            saveAs(gliwiceZbm(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "JASLO_WIERTNIK") {
            saveAs(jasloWiertnik(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "ZIEBICE_ZWIK") {
            saveAs(ziebiceZwik(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "KRASICZYN_GMINA") {
            saveAs(krasiczynGmina(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "PELPLIN_PELKOM") {
            saveAs(pelplinPelkom(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".txt");
        } else if (downloadType === "WILKOWICE_SW") {
            saveAs(wilkowiceSw(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "WARSZAWA_POLITECHNIKA") {
            saveAs(warszawaPolitechnika(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "KETRZYN_ZN") {
            saveAs(ketrzynZn(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "CHOJNICKIE_TBS") {
            saveAs(chojnickieTbs(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".txt");
        } else if (downloadType === "DABROWA_TARNOWSKA_SM") {
            saveAs(dabrowaTarnowskaSm(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "GDANSK_HOMESYSTEM_W") {
            saveAs(gdanskHomesystemW(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "GDANSK_HOMESYSTEM_C") {
            saveAs(gdanskHomesystemC(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "WARSZAWA_VICTUS") {
            saveAs(warszawaVictus(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        } else if (downloadType === "OSM_PROBIT") {
            saveAs(osmProbit(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        } else if (downloadType === "RACIBORZ_DOM_EK") {
            saveAs(raciborzDomEk(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "CZESTOCHOWA_ZN_CTG") {
            saveAs(czestochowaZnCtg(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        } else if (downloadType === "WLOSZAKOWICE") {
            saveAs(wloszakowice(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "OM_ZUK") {
            saveAs(omZuk(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "GDANSK_ENERGETYK") {
            saveAs(gdanskEnergetyk(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "WARSZAWA_GGKO") {
            saveAs(warszawaGgko(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        } else if (downloadType === "SILESIA_SLONIE") {
            saveAs(silesiaSlonie(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        } else if (downloadType === "BYSTRZYCA_KLODZKA") {
            saveAs(bystrzycaKlodzka(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "WEGROW") {
            saveAs(wegrow(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        } else if (downloadType === "PYSKOWICE") {
            saveAs(pyskowice(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        } else if (downloadType === "BTBS_BYTOW") {
            saveAs(btbsBytow(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".xlsx");
        } else if (downloadType === "DWIKOZY") {
            saveAs(dwikozy(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "BRZEZINY_BSM") {
            saveAs(brzezinyBsm(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "GDANSK_BIZAN") {
            saveAs(gdanskBizan(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        } else if (downloadType === "KROSNO_ODRZANSKIE") {
            saveAs(krosnoOdrzanskie(
                Array.from(devicesById.values()).filter(device => selection.length === 0 || selection.includes(device.id)),
                deviceStatusesByDeviceId
            ),
                "metering-" + dateStr + ".csv");
        }
    }

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                    <Paper className={classes.panel}>
                        <TimedValuePanel title="Zużycie ZW"
                            unit=" m3"
                            color="black"
                            timedValue={new TimedValue(time ? time : null, round(totalUsageCold.value, 2))} />
                    </Paper>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Paper className={classes.panel}>
                        <TimedValuePanel title="Zużycie CW"
                            unit=" m3"
                            color="black"
                            timedValue={new TimedValue(time ? time : null, round(totalUsageHot.value, 2))} />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                    <Paper className={classes.panel}>
                        <TimedValuePanel title="Bilans ZW"
                            unit="%"
                            color={calculateBalanceColor(balanceCold)}
                            timedValue={new TimedValue(time ? time : null, round(balanceCold.value, 2))} />
                    </Paper>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Paper className={classes.panel}>
                        <TimedValuePanel title="Bilans CW"
                            unit="%"
                            color={calculateBalanceColor(balanceHot)}
                            timedValue={new TimedValue(time ? time : null, round(balanceHot.value, 2))} />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <RichTable name="Ostatnie zdarzenia" columns={
                        [
                            {
                                id: 'deviceId',
                                hidden: true,
                                numeric: false,
                                disablePadding: false,
                                label: 'ID urządzenia',
                                render: (row) => (<Link className={classes.link}
                                    to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.deviceId + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.deviceId}</Link>)
                            },
                            {
                                id: 'deviceName',
                                numeric: false,
                                disablePadding: false,
                                label: 'Nazwa urządzenia',
                                render: (row) => (<Link className={classes.link}
                                    to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.deviceId + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.deviceName}</Link>)
                            },
                            { id: 'time', numeric: false, disablePadding: false, label: 'Czas' },
                            {
                                id: 'type',
                                numeric: false,
                                disablePadding: false,
                                label: 'Typ'
                            },
                            { id: 'message', numeric: false, disablePadding: false, label: 'Wiadomość' },
                            { id: 'gw', numeric: false, disablePadding: false, label: 'Brama' }
                        ]
                    }
                        initialRowsById={new Map(deviceStatuses.filter(value => value.isAlarm || value.isCalibration).map((status, index) => {
                            const device = devicesById.get(status.deviceId);
                            const id = index;
                            return [id, {
                                deviceId: status.deviceId,
                                deviceName: device.name,
                                time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                                type: status.isAlarm ? "Alarm" : "Info",
                                message: status.isAlarm ? [
                                    chooseAlarm(device, status.time, status.meter1Connected, status.meter2Connected, "COLD", "Podłączenie ZW"),
                                    chooseAlarm(device, status.time, status.meter1Connected, status.meter2Connected, "HOT", "Podłączenie CW"),
                                    chooseAlarm(device, status.time, status.meter1Disconnected, status.meter2Disconnected, "COLD", "Odłączenie ZW"),
                                    chooseAlarm(device, status.time, status.meter1Disconnected, status.meter2Disconnected, "HOT", "Odłączenie CW"),
                                    chooseAlarm(device, status.time, status.meter1DoseExceeded, status.meter2DoseExceeded, "COLD", "Przekroczenie dawki ZW"),
                                    chooseAlarm(device, status.time, status.meter1DoseExceeded, status.meter2DoseExceeded, "HOT", "Przekroczenie dawki CW"),
                                    chooseAlarm(device, status.time, status.meter1HoseCracked, status.meter2HoseCracked, "COLD", "Pęknięcie wężyka ZW"),
                                    chooseAlarm(device, status.time, status.meter1HoseCracked, status.meter2HoseCracked, "HOT", "Pęknięcie wężyka CW"),
                                    chooseAlarm(device, status.time, status.meter1Leak, status.meter2Leak, "COLD", "Przeciek ZW"),
                                    chooseAlarm(device, status.time, status.meter1Leak, status.meter2Leak, "HOT", "Przeciek CW"),
                                    chooseAlarm(device, status.time, status.meter1MinUsageNotReached, status.meter2MinUsageNotReached, "COLD", "Minimalne zużycie ZW nieosiągnięte"),
                                    chooseAlarm(device, status.time, status.meter1MinUsageNotReached, status.meter2MinUsageNotReached, "HOT", "Minimalne zużycie CW nieosiągnięte"),
                                    chooseAlarm(device, status.time, status.valve1Leak, status.valve2Leak, "COLD", "Nieszczelność zaworu ZW"),
                                    chooseAlarm(device, status.time, status.valve1Leak, status.valve2Leak, "HOT", "Nieszczelność zaworu CW"),
                                    chooseAlarm(device, status.time, status.valve1Opened, status.valve2Opened, "COLD", "Otwarcie zaworu ZW"),
                                    chooseAlarm(device, status.time, status.valve1Opened, status.valve2Opened, "HOT", "Otwarcie zaworu CW"),
                                    chooseAlarm(device, status.time, status.valve1Closed, status.valve2Closed, "COLD", "Zamknięcie zaworu ZW"),
                                    chooseAlarm(device, status.time, status.valve1Closed, status.valve2Closed, "HOT", "Zamknięcie zaworu CW"),
                                    status.batteryLow && status.batteryLow.time.getTime() === status.time.getTime() ? (status.batteryLow.value ? "Słaba bateria: " + status.batteryVoltage.value + "V" : null) : null,
                                ].filter(value => value).join("; ") : "Kalibracja: dzienna dawka ZW: " + chooseDailyDose(device, status.time, status.meter1DailyDose, status.meter2DailyDose, "COLD") + " m3, czas międzyimpulsowy ZW: " + chooseInterImpulseTime(device, status.time, status.meter1InterImpulseTime, status.meter2InterImpulseTime, "COLD") + " m3/h, dzienna dawka CW: " + chooseDailyDose(device, status.time, status.meter1DailyDose, status.meter2DailyDose, "HOT") + " m3, natężenie przepływu CW: " + chooseInterImpulseTime(device, status.time, status.meter1InterImpulseTime, status.meter2InterImpulseTime, "HOT") + " m3/h",
                                gw: gwIdToName[status.rxMetadata.gwInfo[0].gwId] ? gwIdToName[status.rxMetadata.gwInfo[0].gwId] : status.rxMetadata.gwInfo[0].gwId,
                            }];
                        }))} initialOrderBy="time" initialOrder="desc" className={classes.lastAlarmsTable} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <RichTable name="Ostatnie odczyty" columns={
                        [
                            {
                                id: 'deviceId',
                                hidden: true,
                                numeric: false,
                                disablePadding: false,
                                label: 'ID urządzenia',
                                render: (row) => (<Link className={classes.link}
                                    to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.deviceId + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.deviceId}</Link>)
                            },
                            {
                                id: 'deviceName',
                                numeric: false,
                                disablePadding: false,
                                label: 'Nazwa urządzenia',
                                render: (row) => (<Link className={classes.link}
                                    to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.deviceId + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.deviceName}</Link>)
                            },
                            { id: 'time', numeric: false, disablePadding: false, label: 'Czas' },
                            { id: 'stateCold', numeric: true, disablePadding: false, label: 'Stan ZW (m3)' },
                            { id: 'stateHot', numeric: true, disablePadding: false, label: 'Stan CW (m3)' },
                            { id: 'stateHeat', numeric: true, disablePadding: false, label: 'Stan ciepła (GJ)' },
                            { id: 'gw', numeric: false, disablePadding: false, label: 'Brama' },
                        ]
                    } initialRowsById={new Map(deviceStatuses.filter(value => value.isReading).map((status, index) => {
                        const device = devicesById.get(status.deviceId);
                        const id = index;
                        return [id, {
                            deviceId: status.deviceId,
                            deviceName: device.name,
                            time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                            stateCold: calculateState(device, status, "COLD").value,
                            stateHot: calculateState(device, status, "HOT").value,
                            stateHeat: calculateState(device, status, "HEAT").value,
                            gw: gwIdToName[status.rxMetadata.gwInfo[0].gwId] ? gwIdToName[status.rxMetadata.gwInfo[0].gwId] : status.rxMetadata.gwInfo[0].gwId
                        }];
                    }))} initialOrderBy="time" initialOrder="desc" className={classes.lastReadingsTable} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <RichTable name="Urządzenia" columns={
                    [
                        { id: 'id', hidden: true, numeric: false, disablePadding: false, label: 'ID' },
                        {
                            id: 'name',
                            numeric: false,
                            disablePadding: false,
                            label: 'Nazwa',
                            render: (row) => (<Link className={classes.link}
                                to={"/organizations/" + organizationId + "/groups/" + groupId + "/devices/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>)
                        },
                        {
                            id: 'time',
                            numeric: false,
                            disablePadding: false,
                            label: 'Czas',
                            render: (row) => (<span
                                style={{ color: moment(row.time, "YYYY-MM-DD HH:mm:ss").isBefore(moment().subtract(2, 'days')) ? "red" : "black" }}>{row.time}</span>)
                        },
                        {
                            id: 'alarm',
                            numeric: true,
                            disablePadding: true,
                            label: 'Alarm',
                            render: (row) => (<Lens style={{
                                color: row.alarm === "yes" ? "#FF0101" : "#6DD400",
                                verticalAlign: "middle",
                                fontSize: "1rem"
                            }} />),
                            visible: true
                        },
                        { id: 'collectorId', numeric: false, disablePadding: false, label: 'ID inkasenta' },
                        { id: 'clientId', numeric: false, disablePadding: false, label: 'ID klienta' },
                        { id: 'clientAddress', numeric: false, disablePadding: false, label: 'Adres klienta' },
                        { id: 'meterIdCold', numeric: false, disablePadding: false, label: 'ID licznika ZW' },
                        { id: 'meterIdHot', numeric: false, disablePadding: false, label: 'ID licznika CW' },
                        { id: 'meterIdHeat', numeric: false, disablePadding: false, label: 'ID licznika ciepła' },
                        { id: 'stateCold', numeric: true, disablePadding: false, label: 'Stan ZW (m3)' },
                        { id: 'stateHot', numeric: true, disablePadding: false, label: 'Stan CW (m3)' },
                        { id: 'stateHeat', numeric: true, disablePadding: false, label: 'Stan ciepła (GJ)' },
                        { id: 'usageCold', numeric: true, disablePadding: false, label: 'Zużycie ZW (m3)' },
                        { id: 'usageHot', numeric: true, disablePadding: false, label: 'Zużycie CW (m3)' },
                        { id: 'usageHeat', numeric: true, disablePadding: false, label: 'Zużycie ciepła (GJ)' },
                        { id: 'description', numeric: false, disablePadding: false, label: 'Opis' },
                        { id: 'lastLoraDr', numeric: false, disablePadding: false, label: 'Ostatni LoRa DR' },
                        { id: 'lastGw', numeric: false, disablePadding: false, label: 'Ostatnia brama' },
                        { id: 'meterSnCold', numeric: false, disablePadding: false, label: 'SN licznika ZW' },
                        { id: 'meterSnHot', numeric: false, disablePadding: false, label: 'SN licznika CW' },
                        { id: 'meterSnHeat', numeric: false, disablePadding: false, label: 'SN licznika ciepła' },
                    ]
                } initialRowsById={new Map(Array.from(devicesById.values()).map(device => {
                    const readingStatuses = deviceStatusesByDeviceId.get(device.id) ? Array.from(deviceStatusesByDeviceId.get(device.id)).filter(value => value.isReading) : [];
                    const alarmStatuses = deviceStatusesByDeviceId.get(device.id) ? Array.from(deviceStatusesByDeviceId.get(device.id)).filter(value => value.isAlarm) : [];

                    return [device.id, {
                        id: device.id,
                        name: device.name,
                        time: (readingStatuses && readingStatuses[0]) ? moment(readingStatuses[0].time).format("YYYY-MM-DD HH:mm:ss") : "",
                        alarm: deviceHasAlarms(alarmStatuses[0]) ? "yes" : "no",
                        collectorId: device.collectorId,
                        clientId: device.clientId,
                        clientAddress: device.clientAddress,
                        meterIdCold: chooseMeterId(device, "COLD"),
                        meterIdHot: chooseMeterId(device, "HOT"),
                        meterIdHeat: chooseMeterId(device, "HEAT"),
                        stateCold: calculateState(device, readingStatuses[0], "COLD").value,
                        stateHot: calculateState(device, readingStatuses[0], "HOT").value,
                        stateHeat: calculateState(device, readingStatuses[0], "HEAT").value,
                        usageCold: calculateUsage(device, readingStatuses[readingStatuses.length - 1], readingStatuses[0], "COLD").value,
                        usageHot: calculateUsage(device, readingStatuses[readingStatuses.length - 1], readingStatuses[0], "HOT").value,
                        usageHeat: calculateUsage(device, readingStatuses[readingStatuses.length - 1], readingStatuses[0], "HEAT").value,
                        description: device.description,
                        lastLoraDr: readingStatuses[0] ? loraDataRateToValue(readingStatuses[0].rxMetadata.dataRate) : null,
                        lastGw: (readingStatuses && readingStatuses[0]) ? (gwIdToName[readingStatuses[0].rxMetadata.gwInfo[0].gwId] ? gwIdToName[readingStatuses[0].rxMetadata.gwInfo[0].gwId] : readingStatuses[0].rxMetadata.gwInfo[0].gwId) : null,
                        meterSnCold: chooseMeterSn(device, "COLD"),
                        meterSnHot: chooseMeterSn(device, "HOT"),
                        meterSnHeat: chooseMeterSn(device, "HEAT")
                    }];
                }))} initialOrderBy="name" initialOrder="asc"
                    selectionEnabled
                    filterEnabled
                    sortingEnabled
                    downloadEnabled
                    onDownload={onDownload}
                    downloadTypes={[
                        { type: "EXCEL", name: "Excel" },
                        { type: "FILA", name: "Fila" }, {
                            type: "FILA_ZUZYCIA",
                            name: "Fila Zużycia"
                        }, { type: "FILA_ZIEBICE", name: "Fila Ziębice" }, {
                            type: "TBS_PULTUSK",
                            name: "TBS Pułtusk"
                        }, { type: "GW_MAX", name: "GW Max" }, {
                            type: "GW_MAX_DARLOWO",
                            name: "GW Max Darłowo"
                        }, { type: "GW_MAX_KISIELICE", name: "GW Max Kisielice" }, {
                            type: "WOD_KAN",
                            name: "WodKan"
                        }, { type: "SKARSZEWIANKA", name: "Skarszewianka" }, {
                            type: "BEWIT",
                            name: "BeWit"
                        }, { type: "SYSTEMEG", name: "SYSTEmEG" },
                        {
                            type: "QNET_LECZYCE",
                            name: "QNET Łęczyce"
                        },
                        {
                            type: "OZAROWICE_WODNIK",
                            name: "Ożarowice Wodnik"
                        }, {
                            type: "ZBM_BYTOM",
                            name: "ZBM Bytom"
                        }, {
                            type: "MESZNA_SW",
                            name: "Meszna SW"
                        },
                        , { type: "GRANIT", name: "GRANIT" }, {
                            type: "WODA_TRABKI_WIELKIE",
                            name: "Woda Trąbki Wielkie"
                        }, {
                            type: "TBS_BRZEZINY_PAPIRUS",
                            name: "TBS Brzeziny - Papirus"
                        }, {
                            type: "USTRONIE_PAPIRUS",
                            name: "Ustronie Papirus"
                        }, {
                            type: "GDANSK_KO",
                            name: "Gdańsk KO"
                        }, {
                            type: "BRZEG_DOLNY_UNISOFT",
                            name: "Brzeg Dolny - Unisoft"
                        }, {
                            type: "GDANSK_POLUDNIE_ZIELONA_GORA_RTBS",
                            name: "Gdańsk Południe Zielona Góra RTBS"
                        }, {
                            type: "GDANSK_POLUDNIE_ZIELONA_GORA_RTBS_2",
                            name: "Gdańsk Południe Zielona Góra RTBS 2"
                        }, {
                            type: "GLIWICE_ZBM",
                            name: "Gliwice ZBM"
                        }, {
                            type: "JASLO_WIERTNIK",
                            name: "Jasło Wiertnik"
                        }, {
                            type: "ZIEBICE_ZWIK",
                            name: "Ziębice ZWIK"
                        }, {
                            type: "KRASICZYN_GMINA",
                            name: "Krasiczyn Gmina"
                        }, {
                            type: "PELPLIN_PELKOM",
                            name: "Pelplin-Pelkom"
                        }, {
                            type: "WILKOWICE_SW",
                            name: "Wilkowice SW"
                        }, {
                            type: "WARSZAWA_POLITECHNIKA",
                            name: "Warszawa Politechnika"
                        }, {
                            type: "KETRZYN_ZN",
                            name: "Kętrzyn ZN"
                        }, {
                            type: "CHOJNICKIE_TBS",
                            name: "Chojnickie TBS"
                        }, {
                            type: "DABROWA_TARNOWSKA_SM",
                            name: "Dąbrowa Tarnowska SM"
                        }, {
                            type: "GDANSK_HOMESYSTEM_W",
                            name: "Gdańsk Homesystem W"
                        }, {
                            type: "GDANSK_HOMESYSTEM_C",
                            name: "Gdańsk Homesystem C"
                        }, {
                            type: "WARSZAWA_VICTUS",
                            name: "Warszawa Victus"
                        }, {
                            type: "OSM_PROBIT",
                            name: "OSM Probit"
                        }, {
                            type: "RACIBORZ_DOM_EK",
                            name: "Racibórz DOM_EK"
                        }, {
                            type: "CZESTOCHOWA_ZN_CTG",
                            name: "Częstochowa ZN CTG"
                        }, {
                            type: "WLOSZAKOWICE",
                            name: "Włoszakowice"
                        }, {
                            type: "OM_ZUK",
                            name: "OM ZUK"
                        }, {
                            type: "GDANSK_ENERGETYK",
                            name: "Gdańsk Energetyk"
                        }, {
                            type: "WARSZAWA_GGKO",
                            name: "Warszawa GGKO"
                        }, {
                            type: "SILESIA_SLONIE",
                            name: "Silesia Słonie"
                        }, {
                            type: "BYSTRZYCA_KLODZKA",
                            name: "Bystrzyca Kłodzka"
                        }, {
                            type: "WEGROW",
                            name: "Węgrów"
                        }, {
                            type: "PYSKOWICE",
                            name: "Pyskowice"
                        }, {
                            type: "BTBS_BYTOW",
                            name: "BTBS Bytów"
                        }, {
                            type: "DWIKOZY",
                            name: "Dwikozy"
                        }, {
                            type: "BRZEZINY_BSM",
                            name: "Brzeziny BSM"
                        }, {
                            type: "GDANSK_BIZAN",
                            name: "Gdańsk Bizan"
                        }, {
                            type: "KROSNO_ODRZANSKIE",
                            name: "Krosno Odrzańskie"
                        }
                    ]}


                    className={classes.devicesTable} />
            </Grid>
        </Container>
    );
}