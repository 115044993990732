export default function TimeRange(from, to) {
    this.from = from;
    this.to = to;

    this.toJson = function() {
        return {
            from: this.from,
            to: this.to
        }
    }
}

TimeRange.fromJson = function(json) {
    return new TimeRange(json.from, json.to);
};