import React from 'react';
import TextField from "@material-ui/core/TextField";
import Form from "./Form";

export default function GroupForm({edit, initialGroup, onSubmit}) {
    const [group, setGroup] = React.useState(initialGroup);

    function onFormSubmit(event) {
        onSubmit(group);
        event.preventDefault();
    }

    function onChange(updateState) {
        setGroup(prevState => {
            const prevStateCloned = Object.assign({}, prevState);
            return updateState(prevStateCloned);
        });
    }

    return <Form title="Instalacja" onSubmit={onFormSubmit} submitButtonLabel={edit ? "Zapisz" : "Dodaj"}>
        <TextField
            name="name"
            label="Nazwa"
            margin="normal"
            value={group.name}
            onChange={event => {
                const value = event.target.value;
                onChange(group => {
                    group.name = value;
                    return group;
                });
            }}
            inputProps={{
                pattern: "[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 -_]+",
            }}
            fullWidth
            required
            helperText={"Dozwolone są tylko znaki alfanumeryczne, spacja, \"-\", \"-\" lub \".\""}
        />
    </Form>;
}