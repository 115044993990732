import React from 'react';
import {Switch, Route, Link, useRouteMatch, useLocation} from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    box: {
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    tabs: {
        display: 'flex',
        flexGrow: 1,
    },
    filter: {
        display: 'flex',
        marginRight: theme.spacing(1),
        justifyContent: 'flex-end'
    }
}));

export default function TabLayout({tabs}) {
    const classes = useStyles();
    const routeMatch = useRouteMatch();
    const parentPath = routeMatch.path.match(/.*\//)[0];
    const location = useLocation();
    const parentUrl = routeMatch.url.match(/.*\//)[0];
    const basename = routeMatch.url.replace(parentUrl, "");
    const search = location.search;
    const tabNameIndexes = new Map(tabs.map((tab, index) => [tab.name, index]));

    return (
        <React.Fragment>
            <div className={classes.box}>
                <Tabs
                    value={tabNameIndexes.get(basename)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    className={classes.tabs}
                >
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} component={Link} to={`${parentUrl}${tab.name}${search}`}/>
                    ))}
                </Tabs>
                {/*<IconButton aria-label="filter" className={classes.filter}>
                    <FilterListIcon/>
                </IconButton>*/}
            </div>
            <Switch>
                {tabs.map((tab, index) => (
                    <Route key={index} path={`${parentPath}${tab.name}`}>
                        {tab.children}
                    </Route>
                ))}
            </Switch>
        </React.Fragment>
    );
}