import React from 'react';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import moment from "moment";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";

export default function TimedValuePanel({ title, unit, color, timedValue }) {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Typography component="p" variant="h4" style={{
        color: color
      }}>{isNaN(timedValue.value) ? "NaN" : (timedValue.value || timedValue.value === 0) ? timedValue.value + unit : <Tooltip title="Brak danych"><WarningIcon style={{ color: "#FF0101" }} /></Tooltip>}</Typography>
      <Typography color="textSecondary" style={{
        fontSize: "0.875rem",
        marginTop: 5
      }}>{timedValue.time ? moment(timedValue.time).format("L LTS") : ""}</Typography>
    </React.Fragment>
  );
}