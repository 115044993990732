import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import DeviceForm from "./DeviceForm";
import Snackbar from "@material-ui/core/Snackbar";
import GroupDeviceAttributesForm from "./GroupDeviceAttributesForm";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    }
}));

export default function DeviceSettings({device, groupDeviceAttributes, onDeviceUpdate, onGroupDeviceAttributesUpdate, admin}) {
    const classes = useStyles();

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    function onDeviceSubmit(device) {
        onDeviceUpdate(device, () => setSnackbarOpen(true));
    }

    function onGroupDeviceAttributesSubmit(groupDeviceAttributes) {
        onGroupDeviceAttributesUpdate(groupDeviceAttributes, () => setSnackbarOpen(true));
    }

    return (
        <React.Fragment>
            <Container maxWidth={false} className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={6} md={6} lg={6}>
                        {device && <DeviceForm edit initialDevice={device} onSubmit={onDeviceSubmit} admin={admin}/>}
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                        {groupDeviceAttributes && <GroupDeviceAttributesForm edit initialGroupDeviceAttributes={groupDeviceAttributes} onSubmit={onGroupDeviceAttributesSubmit}/>}
                    </Grid>
                </Grid>
            </Container>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                onClose={event => setSnackbarOpen(false)}
                autoHideDuration={2000}
                message="Zapisano"
            />
        </React.Fragment>
    );
}