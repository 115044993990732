import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
    calculateTime,
    round,
    truncateTimeToDay,
    calculateBalanceColor,
    calculateBalance
} from "./common";
import {Link} from "react-router-dom";
import RichTable from "./RichTable";
import Tooltip from "@material-ui/core/Tooltip";
import WarningIcon from "@material-ui/icons/Warning";
import Paper from "@material-ui/core/Paper";
import Title from "./Title";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    panel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    chartPanel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 300,
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
    },
    domm: {
        padding: theme.spacing(4)
    }
}));

export default function OrganizationDashboard({
                                                  organizationId,
                                                  since,
                                                  until,
                                                  groupsById,
                                                  devicesByGroupId,
                                                  groupDeviceAttributesByGroupId,
                                                  deviceStatusesByDeviceId
                                              }) {
    const classes = useStyles();

    const initialRows = (groupsById.size > 0 && devicesByGroupId.size > 0 && groupDeviceAttributesByGroupId.size > 0) ? (Array.from(groupsById.values()).map(group => {
        if (devicesByGroupId.get(group.id) && groupDeviceAttributesByGroupId.get(group.id)) {
            const devicesById = new Map(Array.from(devicesByGroupId.get(group.id)).map(value => [value.id, value]));
            const groupDeviceAttributesByDeviceId = new Map(Array.from(groupDeviceAttributesByGroupId.get(group.id)).map(value => [value.deviceId, value]));

            const balanceCold = calculateBalance("COLD", since, until, devicesById, groupDeviceAttributesByDeviceId, deviceStatusesByDeviceId);
            const balanceHot = calculateBalance("HOT", since, until, devicesById, groupDeviceAttributesByDeviceId, deviceStatusesByDeviceId);

            return {
                id: group.id,
                name: group.name,
                time: calculateTime(deviceStatusesByDeviceId),
                balanceCold: balanceCold,
                balanceHot: balanceHot,
            }
        } else {
            return null;
        }
    })).filter(value => value).sort((a, b) => (a.name > b.name ? 1 : -1)) : [];

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper className={classes.panel}>
                        <Title>Bilans</Title>
                        <div className={classes.domm}>
                            <Grid container spacing={6}>
                                {initialRows.map((row, index) => {
                                    return <Grid key={index} item xs={6} md={2} lg={2}>
                                        <Link className={classes.link}
                                              to={"/organizations/" + organizationId + "/groups/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>
                                            <svg viewBox="0 0 232 258" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                 xmlnsXlink="http://www.w3.org/1999/xlink">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g>
                                                        <polygon id="cw" fill={calculateBalanceColor(row.balanceCold)}
                                                                 points="0 86 115 0 115 258 3.98986399e-16 258"></polygon>
                                                        <polygon id="hw" fill={calculateBalanceColor(row.balanceHot)}
                                                                 transform="translate(174.500000, 129.000000) scale(-1, 1) translate(-174.500000, -129.000000) "
                                                                 points="117 86 232 0 232 258 117 258"></polygon>

                                
                                                        { !row.balanceCold.value ? <Tooltip title="Brak danych">
                                                        <g transform="translate(36.000000, 130.000000)">
                                                            <rect fill="#FFFFFF" x="15" y="15" width="13" height="18"></rect>
                                                            <path d="M0,38 L44,38 L22,0 L0,38 Z M24,32 L20,32 L20,28 L24,28 L24,32 Z M24,24 L20,24 L20,16 L24,16 L24,24 Z" fill="#FF0101" fillRule="nonzero"></path>
                                                        </g></Tooltip> :
                                                        <text fontFamily="Roboto-Regular, Roboto" fontSize="26"
                                                              fontWeight="bold" fill="#ffffff">
                                                            <tspan x="13"
                                                                   y="163">{isNaN(row.balanceCold.value) ? "NaN" : round(row.balanceCold.value, 2) + "%"}</tspan>
                                                        </text>}
                                                        { !row.balanceHot.value ? <Tooltip title="Brak danych"><g transform="translate(153.000000, 130.000000)">
                                                            <rect id="Rectangle" fill="#FFFFFF" x="15" y="15" width="13" height="18"></rect>
                                                            <path d="M0,38 L44,38 L22,0 L0,38 Z M24,32 L20,32 L20,28 L24,28 L24,32 Z M24,24 L20,24 L20,16 L24,16 L24,24 Z" id="Shape" fill="#FF0101" fillRule="nonzero"></path>
                                                        </g></Tooltip> :
                                                        <text fontFamily="Roboto-Regular, Roboto" fontSize="26"
                                                              fontWeight="bold" fill="#ffffff">
                                                            <tspan x="130"
                                                                   y="163">{isNaN(row.balanceHot.value) ? "NaN" : round(row.balanceHot.value, 2) + "%"}</tspan>
                                                        </text>}
                                                    </g>
                                                </g>
                                            </svg>
                                            {row.name}</Link>
                                    </Grid>;
                                })}
                            </Grid>
                        </div>
                    </Paper>
                </Grid>
                {/*<Grid item xs={12} md={12} lg={12}>
                    <RichTable name="Bilans" columns={
                        [
                            {
                                id: 'name',
                                numeric: false,
                                disablePadding: false,
                                label: 'Nazwa',
                                render: (row) => (<Link className={classes.link}
                                                        to={"/organizations/" + organizationId + "/groups/" + row.id + "/dashboard?since=" + since.toISOString() + (until ? "&until=" + until.toISOString() : "")}>{row.name}</Link>)
                            },
                            {
                                id: 'balanceCold',
                                numeric: true,
                                disablePadding: true,
                                label: 'ZW (%)',
                                render: (row) => (row.balanceCold === 1 / 0 ? "∞" : (row.balanceCold || row.balanceCold === 0) ?
                                    <span
                                        style={{color: (row.balanceCold || row.balanceCold === 0) ? calculateBalanceColor(row.balanceCold) : "black"}}>{row.balanceCold}</span> :
                                    <Tooltip title="Brak danych lub błędne ustawienia"><WarningIcon
                                        style={{color: "red"}}/></Tooltip>)
                            },
                            {
                                id: 'balanceHot',
                                numeric: true,
                                disablePadding: true,
                                label: 'CW (%)',
                                render: (row) => (row.balanceHot === 1 / 0 ? "∞" : (row.balanceHot || row.balanceHot === 0) ?
                                    <span
                                        style={{color: (row.balanceHot || row.balanceHot === 0) ? calculateBalanceColor(row.balanceHot) : "black"}}>{row.balanceHot}</span> :
                                    <Tooltip title="Brak danych lub błędne ustawienia"><WarningIcon
                                        style={{color: "red"}}/></Tooltip>)
                            }
                        ]
                    } initialRowsById={new Map(initialRows.map(group => {
                        return [group.id, {
                            id: group.id,
                            name: group.name,
                            balanceCold: group.balanceCold,
                            balanceHot: group.balanceHot
                        }];
                    }))} initialOrderBy="name"
                               initialOrder="asc"
                    />
                </Grid>*/}
            </Grid>
        </Container>
    );
}