import TimedValue from "./TimedValue"
import RxMetadata from "./RxMetadata";
import * as moment from "moment";

export default function DeviceStatus(deviceId,
    time,
    seqNo,
    meter1Index,
    meter2Index,
    meter3Index,
    meter1DailyDose,
    meter2DailyDose,
    meter3DailyDose,
    meter1InterImpulseTime,
    meter2InterImpulseTime,
    meter3InterImpulseTime,
    batteryVoltage,
    batteryLow,
    meter1Connected,
    meter2Connected,
    meter3Connected,
    meter1Disconnected,
    meter2Disconnected,
    meter3Disconnected,
    meter1DoseExceeded,
    meter2DoseExceeded,
    meter3DoseExceeded,
    meter1HoseCracked,
    meter2HoseCracked,
    meter3HoseCracked,
    meter1Leak,
    meter2Leak,
    meter3Leak,
    meter1MinUsageNotReached,
    meter2MinUsageNotReached,
    meter3MinUsageNotReached,
    valve1Leak,
    valve2Leak,
    valve3Leak,
    valve1Opened,
    valve2Opened,
    valve3Opened,
    valve1Closed,
    valve2Closed,
    valve3Closed,
    rxMetadata) {
    this.deviceId = deviceId;
    this.time = time;
    this.seqNo = seqNo;
    this.meter1Index = meter1Index;
    this.meter2Index = meter2Index;
    this.meter3Index = meter3Index;
    this.meter1DailyDose = meter1DailyDose;
    this.meter2DailyDose = meter2DailyDose;
    this.meter3DailyDose = meter3DailyDose;
    this.meter1InterImpulseTime = meter1InterImpulseTime;
    this.meter2InterImpulseTime = meter2InterImpulseTime;
    this.meter3InterImpulseTime = meter3InterImpulseTime;
    this.batteryVoltage = batteryVoltage;
    this.batteryLow = batteryLow;
    this.meter1Connected = meter1Connected;
    this.meter2Connected = meter2Connected;
    this.meter3Connected = meter3Connected;
    this.meter1Disconnected = meter1Disconnected;
    this.meter2Disconnected = meter2Disconnected;
    this.meter3Disconnected = meter3Disconnected;
    this.meter1DoseExceeded = meter1DoseExceeded;
    this.meter2DoseExceeded = meter2DoseExceeded;
    this.meter3DoseExceeded = meter3DoseExceeded;
    this.meter1HoseCracked = meter1HoseCracked;
    this.meter2HoseCracked = meter2HoseCracked;
    this.meter3HoseCracked = meter3HoseCracked;
    this.meter1Leak = meter1Leak;
    this.meter2Leak = meter2Leak;
    this.meter3Leak = meter3Leak;
    this.meter1MinUsageNotReached = meter1MinUsageNotReached;
    this.meter2MinUsageNotReached = meter2MinUsageNotReached;
    this.meter3MinUsageNotReached = meter3MinUsageNotReached;
    this.valve1Leak = valve1Leak;
    this.valve2Leak = valve2Leak;
    this.valve3Leak = valve3Leak;
    this.valve1Opened = valve1Opened;
    this.valve2Opened = valve2Opened;
    this.valve3Opened = valve3Opened;
    this.valve1Closed = valve1Closed;
    this.valve2Closed = valve2Closed;
    this.valve3Closed = valve3Closed;
    this.rxMetadata = rxMetadata;

    this.isReading = (
        (this.meter1Index && this.meter1Index.time.getTime() === this.time.getTime()) ||
        (this.meter2Index && this.meter2Index.time.getTime() === this.time.getTime()) ||
        (this.meter3Index && this.meter3Index.time.getTime() === this.time.getTime())
    );

    this.isAlarm = (
        (this.meter1Connected && this.meter1Connected.time.getTime() === this.time.getTime()) ||
        (this.meter2Connected && this.meter2Connected.time.getTime() === this.time.getTime()) ||
        (this.meter3Connected && this.meter3Connected.time.getTime() === this.time.getTime()) ||
        (this.meter1Disconnected && this.meter1Disconnected.time.getTime() === this.time.getTime()) ||
        (this.meter2Disconnected && this.meter2Disconnected.time.getTime() === this.time.getTime()) ||
        (this.meter3Disconnected && this.meter3Disconnected.time.getTime() === this.time.getTime()) ||
        (this.meter1DoseExceeded && this.meter1DoseExceeded.time.getTime() === this.time.getTime()) ||
        (this.meter2DoseExceeded && this.meter2DoseExceeded.time.getTime() === this.time.getTime()) ||
        (this.meter2DoseExceeded && this.meter3DoseExceeded.time.getTime() === this.time.getTime()) ||
        (this.meter1HoseCracked && this.meter1HoseCracked.time.getTime() === this.time.getTime()) ||
        (this.meter2HoseCracked && this.meter2HoseCracked.time.getTime() === this.time.getTime()) ||
        (this.meter3HoseCracked && this.meter3HoseCracked.time.getTime() === this.time.getTime()) ||
        (this.meter1Leak && this.meter1Leak.time.getTime() === this.time.getTime()) ||
        (this.meter2Leak && this.meter2Leak.time.getTime() === this.time.getTime()) ||
        (this.meter3Leak && this.meter3Leak.time.getTime() === this.time.getTime()) ||
        (this.meter1MinUsageNotReached && this.meter1MinUsageNotReached.time.getTime() === this.time.getTime()) ||
        (this.meter2MinUsageNotReached && this.meter2MinUsageNotReached.time.getTime() === this.time.getTime()) ||
        (this.meter3MinUsageNotReached && this.meter3MinUsageNotReached.time.getTime() === this.time.getTime()) ||
        (this.valve1Leak && this.valve1Leak.time.getTime() === this.time.getTime()) ||
        (this.valve2Leak && this.valve2Leak.time.getTime() === this.time.getTime()) ||
        (this.valve3Leak && this.valve3Leak.time.getTime() === this.time.getTime()) ||
        (this.valve1Opened && this.valve1Opened.time.getTime() === this.time.getTime()) ||
        (this.valve2Opened && this.valve2Opened.time.getTime() === this.time.getTime()) ||
        (this.valve3Opened && this.valve3Opened.time.getTime() === this.time.getTime()) ||
        (this.valve1Closed && this.valve1Closed.time.getTime() === this.time.getTime()) ||
        (this.valve2Closed && this.valve2Closed.time.getTime() === this.time.getTime()) ||
        (this.valve3Closed && this.valve3Closed.time.getTime() === this.time.getTime()) ||
        (this.batteryLow && this.batteryLow.time.getTime() === this.time.getTime())
    );

    this.isCalibration = (
        (this.meter1DailyDose && this.meter1DailyDose.time.getTime() === this.time.getTime()) ||
        (this.meter2DailyDose && this.meter2DailyDose.time.getTime() === this.time.getTime()) ||
        (this.meter3DailyDose && this.meter3DailyDose.time.getTime() === this.time.getTime()) ||
        (this.meter1InterImpulseTime && this.meter1InterImpulseTime.time.getTime() === this.time.getTime()) ||
        (this.meter2InterImpulseTime && this.meter2InterImpulseTime.time.getTime() === this.time.getTime()) ||
        (this.meter3InterImpulseTime && this.meter3InterImpulseTime.time.getTime() === this.time.getTime())
    );
}

DeviceStatus.fromJson = function (json) {
    return new DeviceStatus(
        json.deviceId,
        new Date(Date.parse(json.time)),
        json.seqNo,
        TimedValue.fromJson(json.meter1Index),
        TimedValue.fromJson(json.meter2Index),
        TimedValue.fromJson(json.meter3Index),
        TimedValue.fromJson(json.meter1DailyDose),
        TimedValue.fromJson(json.meter2DailyDose),
        TimedValue.fromJson(json.meter3DailyDose),
        (json.meter1InterImpulseTime ? new TimedValue(
            new Date(Date.parse(json.meter1InterImpulseTime.time)),
            moment.duration(json.meter1InterImpulseTime.value)) : null),
        (json.meter2InterImpulseTime ? new TimedValue(
            new Date(Date.parse(json.meter2InterImpulseTime.time)),
            moment.duration(json.meter2InterImpulseTime.value)) : null),
        (json.meter3InterImpulseTime ? new TimedValue(
            new Date(Date.parse(json.meter3InterImpulseTime.time)),
            moment.duration(json.meter3InterImpulseTime.value)) : null),
        TimedValue.fromJson(json.batteryVoltage),
        TimedValue.fromJson(json.batteryLow),
        TimedValue.fromJson(json.meter1Connected),
        TimedValue.fromJson(json.meter2Connected),
        TimedValue.fromJson(json.meter3Connected),
        TimedValue.fromJson(json.meter1Disconnected),
        TimedValue.fromJson(json.meter2Disconnected),
        TimedValue.fromJson(json.meter3Disconnected),
        TimedValue.fromJson(json.meter1DoseExceeded),
        TimedValue.fromJson(json.meter2DoseExceeded),
        TimedValue.fromJson(json.meter3DoseExceeded),
        TimedValue.fromJson(json.meter1HoseCracked),
        TimedValue.fromJson(json.meter2HoseCracked),
        TimedValue.fromJson(json.meter3HoseCracked),
        TimedValue.fromJson(json.meter1Leak),
        TimedValue.fromJson(json.meter2Leak),
        TimedValue.fromJson(json.meter3Leak),
        TimedValue.fromJson(json.meter1MinUsageNotReached),
        TimedValue.fromJson(json.meter2MinUsageNotReached),
        TimedValue.fromJson(json.meter3MinUsageNotReached),
        TimedValue.fromJson(json.valve1Leak),
        TimedValue.fromJson(json.valve2Leak),
        TimedValue.fromJson(json.valve3Leak),
        TimedValue.fromJson(json.valve1Opened),
        TimedValue.fromJson(json.valve2Opened),
        TimedValue.fromJson(json.valve3Opened),
        TimedValue.fromJson(json.valve1Closed),
        TimedValue.fromJson(json.valve2Closed),
        TimedValue.fromJson(json.valve3Closed),
        RxMetadata.fromJson(json.rxMetadata));
};